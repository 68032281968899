import { createApiAction } from 'api';
import { StartAction, SuccessAction, FailureAction } from 'api/redux/types'; // todo fix this importing

export enum GeneratedDocumentsActionTypes {
  FETCH_GENERATED_DOCUMENTS_START = 'getGeneratedDocuments/fetch_start',
  FETCH_GENERATED_DOCUMENTS_SUCCESS = 'getGeneratedDocuments/fetch_success',
  FETCH_GENERATED_DOCUMENTS_FAILURE = 'getGeneratedDocuments/fetch_failure',
}

const fetchGeneratedDocumentsStart = (): StartAction => ({
  type: GeneratedDocumentsActionTypes.FETCH_GENERATED_DOCUMENTS_START,
  payload: null,
});

const fechGeneratedDocumentsSuccess = (payload: any /*TODO*/): SuccessAction => ({
  type: GeneratedDocumentsActionTypes.FETCH_GENERATED_DOCUMENTS_SUCCESS,
  payload,
});

const fetchGeneratedDocumentsFailure = (error: any /*TODO*/): FailureAction => ({
  type: GeneratedDocumentsActionTypes.FETCH_GENERATED_DOCUMENTS_FAILURE,
  payload: { error },
});

export const fetchGeneratedDocuments = () =>
  createApiAction(
    {
      path: '/ccr/client/getGeneratedDocuments.json',
      method: 'GET',
    },
    fetchGeneratedDocumentsStart,
    fechGeneratedDocumentsSuccess,
    fetchGeneratedDocumentsFailure
  );
