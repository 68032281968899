import { createApiAction } from 'api';
import { StartAction, SuccessAction, FailureAction } from 'api/redux/types'; // todo fix this importing

export enum UserActionTypes {
  FETCH_CREDITWORTHINESS_START = 'creditworthiness/fetch_start',
  FETCH_CREDITWORTHINESS_SUCCESS = 'creditworthiness/fetch_success',
  FETCH_CREDITWORTHINESS_FAILURE = 'creditworthiness/fetch_failure',
}

const getCreditworthinessStart = (): StartAction => ({
  type: UserActionTypes.FETCH_CREDITWORTHINESS_START,
  payload: null,
});

const getCreditworthinessSuccess = (payload: any /*TODO*/): SuccessAction => ({
  type: UserActionTypes.FETCH_CREDITWORTHINESS_SUCCESS,
  payload,
});

const getCreditworthinessFailure = (error: any /*TODO*/): FailureAction => ({
  type: UserActionTypes.FETCH_CREDITWORTHINESS_FAILURE,
  payload: { error },
});

export const fetchCreditWorthiness = () =>
  createApiAction(
    {
      path: '/ccr/client/getEmployer.json',
      method: 'GET',
    },
    getCreditworthinessStart,
    getCreditworthinessSuccess,
    getCreditworthinessFailure
  );
