import { consentTypes } from './const';
import { User } from 'user';

export const getConsentsArrayFromUser = (user: User): number[] => {
  const consents: number[] = [];
  if (user.consents.cookie) {
    const consentType = consentTypes.find(i => i.name === 'cookie');
    consents.push((consentType as any).id);
  }

  if (user.consents.gdpr) {
    const consentType = consentTypes.find(i => i.name === 'gdpr');
    consents.push((consentType as any).id);
  }

  if (user.consents.disclaimer) {
    const consentType = consentTypes.find(i => i.name === 'disclaimer');
    consents.push((consentType as any).id);
  }

  if (user.consents.marketing) {
    const consentType = consentTypes.find(i => i.name === 'marketing');
    consents.push((consentType as any).id);
  }

  return consents;
};
