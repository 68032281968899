export enum FileContentType {
  PDF = 'pdf',
}

export enum GeneratedDocumentType {
  PROPOSAL = 'RequestForProposal.jasper',
  REPORT = 'runReport.json',
}

export enum GeneratedDocumentStatus {
  SIGNED = 'signed',
  UNSIGNED = 'unsigned',
}

export interface GeneratedDocument {
  personalId: null | string;
  description: null | string;
  userSessionId: null | string;
  id: null | number;
  fileContentType: null | FileContentType;
  documentType: null | GeneratedDocumentType;
  fileSize: null | number;
  signatureDate: null | Date;
  timestamp: null | Date;
  status: null | GeneratedDocumentStatus;
}

export interface GeneratedDocuments {
  generatedDocuments: GeneratedDocument[];
}