import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { getFileUploadApiRequest, getApiRequest, ApiMethod } from 'api';
import { ROUTES } from 'routes';

import { Chips } from 'onboarding/components/Chips';
import { DocUploader } from 'onboarding/components/DocUploader';

import { OnboardingPageCmp } from '../../types';
import { OnboardingPageContainer } from '../../components';
import { ChipData } from 'onboarding/components/Chips/types';

const BankInfoPage: OnboardingPageCmp = () => {
  const { enqueueSnackbar } = useSnackbar();
  const fileUploadApiRequest = useSelector(getFileUploadApiRequest);
  const apiRequest = useSelector(getApiRequest);
  const [isPSD2Uploaded, setIsPSD2Uploaded] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const fetchPSD2 = async () => {
    const response = await apiRequest({
      method: ApiMethod.GET,
      path: 'psd2/getTransactions.json',
    });

    if (response && response.data.length) {
      setIsPSD2Uploaded(true);
    }
  };

  useEffect(() => {
    fetchPSD2();
    // eslint-disable-next-line
  }, []);

  const PSD2Chips: ChipData[] = [
    {
      id: 0,
      name: 'PSD2 datoteka',
      description: 'PSD2 datoteka treba biti u .json formatu',
      isActive: isPSD2Uploaded,
    },
  ];

  const uploadDocuments = async (files: File[]): Promise<boolean> => {
    try {
      setUploadInProgress(true);
      await fileUploadApiRequest({
        path: 'psd2/postPsd2TransactionsFiles.json',
        files,
      });
      enqueueSnackbar('Dokument uspješno učitan', { variant: 'info' });
      return true;
    } catch (e) {
      enqueueSnackbar(
        'Nastao je problem prilikom učitavanja dokumenta. Molimo pokušajte ponovo',
        { variant: 'warning' }
      );
      return false;
    } finally {
      setUploadInProgress(false);
      fetchPSD2();
    }
  };

  const renderDocSelector = () => (
    <>
      <Grid item>
        <Typography variant="body1">
          Za nastavak podizanja kredita su nam potrebani vasi PSD2 podaci u JSON
          formatu.
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Chips data={PSD2Chips} />
      </Grid>
    </>
  );

  return (
    <OnboardingPageContainer
      title="Potrebni dokumenti"
      buttonHoverMsg="Molimo učitajte sve potrebne dokumente prije nastavka"
      nextButtonActive={!uploadInProgress}
      previousButtonActive={!uploadInProgress}
      // nextButtonActive={isPSD2Uploaded} // TODO see
    >
      <Grid container spacing={2} direction="column">
        {renderDocSelector()}
        <Grid item xs={6}>
          <DocUploader onUploadPress={uploadDocuments} />
        </Grid>
        {uploadInProgress && (
          <Grid item xs={6}>
            <Typography variant="body1">Učitanje dokumenata...</Typography>
            <LinearProgress variant="indeterminate" />
          </Grid>
        )}
      </Grid>
    </OnboardingPageContainer>
  );
};

BankInfoPage.route = ROUTES.bankInfo;

export default BankInfoPage;
