import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';

import { theme } from 'theme';
import { snackbarOptions } from 'snackbar/const';
import { store } from 'app/redux';

import { AppHome } from './AppHome';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider {...snackbarOptions}>
          <CssBaseline />
          <BrowserRouter>
            <Switch>
              <Route path={AppHome.route} component={AppHome} />
            </Switch>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
