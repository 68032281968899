import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { WelcomePage } from '../welcome';
import { CreditTypePage } from '../credit-type';
import { CreditWorthinessPage } from '../creditworthiness';
import { DocumentsPage } from '../documents';
import { BankInfoPage } from '../bank-info';
import { DebitPage } from '../debit';
import { EmployerPage } from '../employer';
import { CreditOptionsPage } from '../credit-options';
import { OverviewPage } from '../overview';
import { OfferPage } from '../offer';

import { OnboardingRouterCmp } from './types';

const OnboardingRouter: OnboardingRouterCmp = () => {
  return (
    <Switch>
      <Route path={WelcomePage.route} render={() => <WelcomePage />} exact />
      <Route
        path={CreditTypePage.route}
        render={() => <CreditTypePage />}
        exact
      />
      <Route
        path={DocumentsPage.route}
        render={() => <DocumentsPage />}
        exact
      />
      <Route path={BankInfoPage.route} render={() => <BankInfoPage />} exact />
      <Route path={DebitPage.route} render={() => <DebitPage />} exact />
      <Route path={EmployerPage.route} render={() => <EmployerPage />} exact />
      <Route
        path={CreditWorthinessPage.route}
        render={() => <CreditWorthinessPage />}
        exact
      />
      <Route
        path={CreditOptionsPage.route}
        render={() => <CreditOptionsPage />}
        exact
      />
      <Route path={OverviewPage.route} render={() => <OverviewPage />} exact />
      <Route path={OfferPage.route} render={() => <OfferPage />} exact />
      {/* This redirect will be matched if none of above routes do  */}
      <Redirect to={WelcomePage.route} />
    </Switch>
  );
};

export default OnboardingRouter;
