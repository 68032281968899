import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { CARD_HEIGHT, CARD_WIDTH } from './const';

export const useCardStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    minWidth: CARD_WIDTH,
    maxWidth: CARD_WIDTH,
    minHeight: CARD_HEIGHT,
    maxHeight: CARD_HEIGHT,
    position: 'relative',
    borderRadius: 4,
    border: `1px solid ${theme.palette.text.secondary}`,
    cursor: 'pointer',
    padding: theme.spacing() * 2,
  },
  cardActive: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  radio: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  infoIcon: {
    position: 'absolute',
    padding: theme.spacing(),
    boxSizing: 'content-box',
    bottom: 0,
    right: 0,
    cursor: 'help',
  },
  creditIcon: {
    width: theme.spacing() * 10,
    height: theme.spacing() * 10,
  },
}));
