import React from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';

import { DocumentListCmp, DocumentListProps, SkeletonCmp } from './types';
import DocumentListItem from './DocumentListItem';

const DocumentList: DocumentListCmp = (props: DocumentListProps) => {
  const { documents } = props;

  if (documents.length === 0) {
    return null;
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="generated-documents-content"
          id="generated-documents-header"
        >
          <Typography>
            Dostupni dokumenti za preuzimanje i potpisivanje
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List style={{ width: '100%' }}>
            {_.map(documents, document => (
              <DocumentListItem
                key={`list${document.id}item`}
                document={document}
                onDownload={props.onDownload}
                onSign={props.onSign}
              />
            ))}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

const DocumentListSkeleton: SkeletonCmp = () => {
  return <Skeleton height={48} width={1136} />;
};

DocumentList.Skeleton = DocumentListSkeleton;

export default DocumentList;
