import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: { main: '#0049B3' },
    secondary: { main: '#00D3C8' },
    error: { main: '#C10834' },
  },
  typography: {
    fontFamily: ['Roboto'].join(','),
  },
});

export { icons, IconNames } from './icons';
