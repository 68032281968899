import _ from 'lodash';
import { ROUTES, ONBOARDING_ROUTE_INDEX, RoutesKeys } from 'routes';

export const getOnboardingStepIdFromUrl = (pathname: string): number | null => {
  const matchedPair = _.toPairs(ROUTES).find(
    (pair: [string, string]): boolean => {
      const routePathName = pair[1];
      return routePathName === pathname;
    }
  );

  if (!matchedPair) {
    return null;
  }

  const routeKey = matchedPair[0] as RoutesKeys;

  return ONBOARDING_ROUTE_INDEX[routeKey];
};
