import React, { useState } from 'react';
import {
  LinearProgress,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import BorderColorIcon from '@material-ui/icons/BorderColor';

import { DocumentListItemCmp, DocumentListItemProps } from './types';

const DocumentListItem: DocumentListItemCmp = (
  props: DocumentListItemProps
) => {
  const { document } = props;
  const [actionInProgress, setActionInProgress] = useState(false);

  const isDocumentSigned = document.status === 'signed';

  const onSignDocument = () => {
    setActionInProgress(true);
    props.onSign(document.id!).finally(() => setActionInProgress(false));
  };

  const onDownloadDocument = () => {
    setActionInProgress(true);
    props.onDownload(document.id!).finally(() => setActionInProgress(false));
  };

  return (
    <>
      {actionInProgress && <LinearProgress variant="indeterminate" />}
      <ListItem
        style={{ display: 'flex', justifyContent: 'space-between' }}
        divider
        button
      >
        <ListItemText
          primary={document.documentType}
          secondary={isDocumentSigned ? 'Digitalno potpisan' : null}
        />
        <ListItemSecondaryAction>
          {!isDocumentSigned && (
            <Tooltip title="Digitalno potpiši">
              <IconButton
                edge="end"
                aria-label={`sign${document.id}`}
                color={'primary'}
                onClick={onSignDocument}
              >
                <BorderColorIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Preuzmi dokument">
            <IconButton
              edge="end"
              aria-label={`download${document.id}`}
              color={'primary'}
              onClick={onDownloadDocument}
            >
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

export default DocumentListItem;
