export * from './types';

export {
  fetchGeneratedDocuments,
  reducer,
  getGeneratedDocuments,
  getGeneratedDocumentsEmpty,
  getGeneratedDocumentsLoading,
  getLastProposal,
} from './redux';
