import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';

import { reducer as userReducer } from 'user';
import { reducer as formDataReducer } from 'formData';
import { reducer as creditworthinessReducer } from 'creditworthiness';
import { reducer as creditOptionsReducer } from 'credit-options';
import { reducer as generatedDocumentsReducer } from 'generatedDocuments';
import { reducer as onboardingReducer } from 'onboarding';

import { AppStore } from './types';

const reducer = combineReducers({
  user: userReducer,
  formData: formDataReducer,
  creditworthiness: creditworthinessReducer,
  creditOptions: creditOptionsReducer,
  generatedDocuments: generatedDocumentsReducer,
  onboarding: onboardingReducer,
});

const middlewares = [thunk, apiMiddleware];

function getCompose() {
  const IS_BROWSER = typeof window !== 'undefined' && 'HTMLElement' in window;
  if (IS_BROWSER && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    return (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }
  return compose;
}

const composeEnhancers = getCompose();

export const store: AppStore = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
