export enum ROUTES {
  appHome = '/',
  welcome = '/onboarding/welcome',
  creditType = '/onboarding/credit',
  documents = '/onboarding/documents',
  bankInfo = '/onboarding/bank',
  debit = '/onboarding/debit',
  employer = '/onboarding/employer',
  creditworthiness = '/onboarding/creditworthiness',
  creditOptions = '/onboarding/creditoptions',
  overview = '/onboarding/overview',
  offer = '/onboarding/offer',
}

export enum ONBOARDING_ROUTE_NAMES {
  welcome = 'Dobrodošli',
  creditType = 'Vrsta kredita',
  documents = 'Dokumenti',
  bankInfo = 'Bankovni podaci',
  debit = 'Dugovanja',
  employer = 'Poslodavac',
  creditworthiness = 'Bonitet',
  creditOptions = 'Iznos kredita',
  overview = 'Pregled',
  offer = 'Ponuda',
}

export enum ONBOARDING_ROUTE_INDEX {
  welcome = 1,
  creditType = 2,
  documents = 3,
  bankInfo = 4,
  debit = 5,
  employer = 6,
  creditworthiness = 7,
  creditOptions = 8,
  overview = 9,
  offer = 10,
}

export type RoutesKeys =
  | 'welcome'
  | 'creditType'
  | 'documents'
  | 'bankInfo'
  | 'debit'
  | 'employer'
  | 'creditworthiness'
  | 'creditOptions'
  | 'overview'
  | 'offer';
