/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Button, LinearProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

import {
  getGenerateReportApiRequest,
  getHwcryptoGenerateHashApiRequest,
  getHwcryptoCreateContainerApiRequest,
  getDownloadDocumentApiRequest,
} from 'api';
import { ROUTES } from 'routes';

import { OnboardingPageCmp } from '../../types';
import { OnboardingPageContainer } from '../../components';
import { getUser } from 'user';
import {
  fetchGeneratedDocuments,
  getGeneratedDocuments,
  getLastProposal,
  GeneratedDocumentType,
  getGeneratedDocumentsEmpty,
  getGeneratedDocumentsLoading,
  GeneratedDocument,
} from 'generatedDocuments';
import { DocumentList, UserInfo } from '../components';
import {
  extractDocumentContent,
  generateDocumentName,
  isDocumentSigned,
} from './utils';

const OfferPage: OnboardingPageCmp = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const downloadApiRequest = useSelector(getDownloadDocumentApiRequest);
  const generatedDocuments = useSelector(getGeneratedDocuments);
  const isGeneratedDocumentsEmpty = useSelector(getGeneratedDocumentsEmpty);
  const isGeneratedDocumentsLoading = useSelector(getGeneratedDocumentsLoading);
  const generateReportApiRequest = useSelector(getGenerateReportApiRequest);
  const hwcryptoGenerateHashApiRequest = useSelector(
    getHwcryptoGenerateHashApiRequest
  );
  const hwcryptoCreateContainerApiRequest = useSelector(
    getHwcryptoCreateContainerApiRequest
  );
  const user = useSelector(getUser);
  const lastProposal = useSelector(getLastProposal);

  const [proposalDocument, setProposalDocument] = useState<
    GeneratedDocument | undefined
  >(undefined);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    dispatch(fetchGeneratedDocuments());
    if (initialRender) {
      setInitialRender(false);
    }
  }, [dispatch]);

  // TODO: remove after generating proposal returns document id of a proposal
  useEffect(() => {
    setProposalDocument(lastProposal);
  }, [generatedDocuments]);

  // method for generating proposal
  const generateProposal = async () => {
    setActionInProgress(true);

    const response = await generateReportApiRequest({
      body: { report: GeneratedDocumentType.PROPOSAL, pid: user.id },
    });

    setActionInProgress(false);
    if (response.success) {
      downloadPDF(response.data, generateDocumentName('Ponuda', user));
      dispatch(fetchGeneratedDocuments());
    }
  };

  const signDocument = async (documentId: number) => {
    let certificate: any;

    setActionInProgress(true);
    return getHwcryptoCertificate()
      .then(cert => {
        if (cert) {
          certificate = cert;
          return generateHash(certificate.hex, documentId);
        }
        return Promise.reject();
      })
      .then(digest => {
        return (window as any).hwcrypto.sign(
          // @ts-ignore
          certificate,
          { type: 'SHA-256', hex: digest },
          { lang: 'en' }
        );
      })
      .then(signature => {
        return hwcryptoCreateContainerApiRequest(signature.hex).then(() => {
          dispatch(fetchGeneratedDocuments());
        });
      })
      .catch((e: Error) => {
        console.error(e);
        enqueueSnackbar(
          'Nastao je problem prilikom potpisivanja dokumenta. Molimo pokušajte ponovo',
          { variant: 'warning' }
        );
        return Promise.reject();
      })
      .finally(() => {
        setActionInProgress(false);
      });
  };

  const downloadDocument = async (documentId: number) => {
    setActionInProgress(true);
    if (documentId) {
      const response = await downloadApiRequest(documentId);

      if (response && response.data.length) {
        const documentContent = extractDocumentContent(response.data[0]);
        downloadPDF(documentContent, generateDocumentName(undefined, user));
      }
    }
    setActionInProgress(false);
    return Promise.resolve();
  };

  const getHwcryptoCertificate = (): Promise<any> => {
    let cert: any;

    return (window as any).hwcrypto
      .getCertificate({ lang: 'en' })
      .then((certificate: any) => {
        cert = certificate;
        return Promise.resolve(cert);
      });
  };

  const generateHash = async (certificateHex: string, documentId: number) => {
    const response = await hwcryptoGenerateHashApiRequest(
      certificateHex,
      documentId
    );

    if (response.data.result === 'ok') {
      return Promise.resolve(response.data.hex);
    }

    enqueueSnackbar(
      'Nastao je problem prilikom generiranja potpisa. Molimo pokušajte ponovo',
      { variant: 'warning' }
    );
    return Promise.reject();
  };

  const downloadPDF = (pdfContent: any, pdfName: string) => {
    const linkSource = `data:application/pdf;base64,${pdfContent}`;
    const downloadLink = document.createElement('a');
    const today = new Date().toISOString().split('T')[0];
    const fileName = `${today}_${pdfName}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const signAndDownloadProposal = async () => {
    if (!!proposalDocument) {
      if (isDocumentSigned(proposalDocument)) {
        downloadDocument(proposalDocument.id!);
      } else {
        await signDocument(proposalDocument.id!).then(() => {
          downloadDocument(proposalDocument.id!);
        });
      }
    }
  };

  const renderRealData = () => {
    return (
      <>
        <Box mb={3}>
          <Typography>Molimo unesite kontakt podatke</Typography>
        </Box>
        <Box mb={3}>
          <UserInfo />
        </Box>
        <Box
          mb={3}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={generateProposal}
          >
            Zatraži ponudu
          </Button>
          {!!proposalDocument && (
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={signAndDownloadProposal}
            >
              {isDocumentSigned(proposalDocument)
                ? 'Preuzmi potpisanu ponudu'
                : 'Potpiši i preuzmi ponudu'}
            </Button>
          )}
        </Box>
        {actionInProgress && <LinearProgress variant="indeterminate" />}
        <DocumentList
          documents={generatedDocuments}
          onDownload={downloadDocument}
          onSign={signDocument}
        />
      </>
    );
  };

  const renderSkeletons = () => {
    return (
      <>
        <Box mb={3}>
          <Skeleton height={24} />
        </Box>
        <DocumentList.Skeleton />
      </>
    );
  };

  const shouldLoadSkeleton =
    (isGeneratedDocumentsEmpty || isGeneratedDocumentsLoading) && initialRender;

  return (
    <OnboardingPageContainer
      title="Zatražite ponudu za željeni kredit"
      nextButtonActive={false}
    >
      {shouldLoadSkeleton ? renderSkeletons() : renderRealData()}
    </OnboardingPageContainer>
  );
};

OfferPage.route = ROUTES.offer;

export default OfferPage;
