import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { getFileUploadApiRequest } from 'api';
import { ROUTES } from 'routes';
import { getUser, fetchUser } from 'user';

import { Chips } from 'onboarding/components/Chips';
import { DocUploader } from 'onboarding/components/DocUploader';

import { OnboardingPageCmp } from '../../types';
import { OnboardingPageContainer } from '../../components';
import {
  getLoanDocuments,
  getPartnerLoanDocuments,
  getShowPartnerDocuments,
} from '../../redux';
import {
  transformUserDocumentToChipData,
  transformPartnerDocumentToChipData,
  checkIfAllDocsAreUploaded,
} from 'onboarding/components/Chips/utils';

const DocumentsPage: OnboardingPageCmp = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const fileUploadApiRequest = useSelector(getFileUploadApiRequest);
  const loanDocuments = useSelector(getLoanDocuments);
  const userDocuments = transformUserDocumentToChipData(loanDocuments);
  const partnerLoanDocuments = useSelector(getPartnerLoanDocuments);
  const partnerDocuments = transformPartnerDocumentToChipData(
    partnerLoanDocuments
  );
  const shouldShowPartnerDocuments = useSelector(getShowPartnerDocuments);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const uploadDocuments = async (files: File[]): Promise<boolean> => {
    try {
      setUploadInProgress(true);
      await fileUploadApiRequest({
        path: 'filesUpload.json',
        files,
      });
      enqueueSnackbar('Dokumenti su uspješno učitani', { variant: 'info' });
      return true;
    } catch (e) {
      enqueueSnackbar(
        'Nastao je problem prilikom učitavanja dokumenata. Molimo pokušajte ponovo',
        { variant: 'warning' }
      );
      return false;
    } finally {
      setUploadInProgress(false);
      dispatch(fetchUser());
    }
  };

  const renderUserDocSelector = () => (
    <>
      <Grid item>
        <Typography variant="body1">
          Za podizanje potrošačkog kredita potrebno je učitati navedene
          dokumente u PDF formatu:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Chips data={userDocuments} />
      </Grid>
    </>
  );

  const renderSpouseDocSelector = () => (
    <>
      <Grid item>
        <Typography variant="body1">
          Iz učitanog dokumenta o bračnom stanju vidiljivo je da ste u braku.
          Molim Vas učitajte dodatne dokumente s potrebnim infromacijama o Vašem
          bračnom partneru.
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Chips data={partnerDocuments} />
      </Grid>
    </>
  );

  return (
    <OnboardingPageContainer
      title="Potrebni dokumenti"
      buttonHoverMsg="Molimo učitajte sve potrebne dokumente prije nastavka"
      nextButtonActive={
        !uploadInProgress &&
        checkIfAllDocsAreUploaded(loanDocuments, partnerLoanDocuments)
      }
      previousButtonActive={!uploadInProgress}
    >
      <Grid container spacing={2} direction="column">
        {renderUserDocSelector()}
        {user.isMarried && shouldShowPartnerDocuments && partnerDocuments.length
          ? renderSpouseDocSelector()
          : null}
        <Grid item xs={6}>
          <DocUploader onUploadPress={uploadDocuments} />
        </Grid>
        {uploadInProgress && (
          <Grid item xs={6}>
            <Typography variant="body1">Učitavanje dokumenata...</Typography>
            <LinearProgress variant="indeterminate" />
          </Grid>
        )}
      </Grid>
    </OnboardingPageContainer>
  );
};

DocumentsPage.route = ROUTES.documents;

export default DocumentsPage;
