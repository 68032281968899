import { KeysToShow } from './types';

export const basicInfoKeysWithLabels: KeysToShow = Object.freeze({
  fullName: 'Puni naziv',
  oib: 'OIB',
  shortName: 'Naziv',
  dateAndPlaceOfFoundation: 'Osnovano',
  location: 'Sjedište',
  numberOfEmployees: 'Broj Zaposlenih',
  website: 'URL',
  companySize: 'Veličina subjekta',
  originOfCapital: 'Podrijetlo kapitala',
  nkd: 'NKD',
});

export const currentInfoKeysWithLabels: KeysToShow = Object.freeze({
  isActive: 'Aktivno',
  bankruptcy: 'Stečaj',
  blockadeStatus: 'Blokada',
  liquidation: 'Likvidacija',
})
