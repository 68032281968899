import { makeStyles } from '@material-ui/styles';
import styed from 'styled-components';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Link } from 'react-router-dom';

export const useBreadcrumbStyles = makeStyles((theme: Theme) => ({
  activeStep: {
    'font-weight': 'bold',
    color: theme.palette.primary.main,
    'border-bottom': '1px solid',
    'margin-bottom': '-1px',
  },
  finishedStep: {
    'font-weight': 'bold',
    color: theme.palette.primary.main,
  },
  bottomPadding: {
    'padding-bottom': theme.spacing() * 3,
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const NoStyleLink = styed(Link)`
  text-decoration: inherit;
  color: inherit;
`;
