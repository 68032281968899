import React from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '@material-ui/styles';

import { ChartCmp, ChartProps } from './types';

const CompanyChart: ChartCmp = ({ data }: ChartProps) => {
  const theme: any = useTheme();

  const series = [
    {
      name: 'Aktiva (Imovina)',
      data: data.map(item => item.assets),
    },
    {
      name: 'Kapital i Rezerve',
      data: data.map(item => item.capital),
    },
  ];

  const options = {
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data.map(item => item.year),
    },
    yaxis: {
      title: {
        text: 'HRK',
      },
    },
    fill: {
      opacity: 1,
    },
    colors: [theme.palette.primary.main, theme.palette.secondary.main],
  };
  

  return <Chart options={options} series={series} type="bar" height="350" />;
};

export default CompanyChart;
