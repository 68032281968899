import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const useDocStyles = makeStyles((theme: Theme) => ({
  root: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderRadius: 4,
    padding: theme.spacing(2),
    borderColor: theme.palette.text.secondary,
    minHeight: 301,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flex: 1,
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));
