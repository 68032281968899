import React, { useEffect, useState, ChangeEvent } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { getUserApiRequest, ApiMethod } from 'api';

import { ROUTES } from 'routes';
import { getExistingCredit } from 'user/redux/selectors';

import { OnboardingPageCmp } from '../../types';
import { OnboardingPageContainer, NumberFormatInput } from '../../components';
import {
  CONSENT_CHECK_ID,
  PAYMENTS_CHECK_ID,
  OVERALL_DEBT_ID,
  MONTHLY_DEBT_ID,
} from './const';

const DebitPage: OnboardingPageCmp = () => {
  const userApiRequest = useSelector(getUserApiRequest);
  const existingCredit = useSelector(getExistingCredit);

  const [consentCheck, setConsentCheck] = useState(false);
  const [regularPaymentsCheck, setRegularPaymentsCheck] = useState(false);
  const [overallDebt, setOverallDebt] = useState<number | null>(null);
  const [monthlyRate, setMonthlyRate] = useState<number | null>(null);
  const [nextButtonActive, setNextButtonActive] = useState<boolean>(false);
  const [buttonHoverMsg, setButtonHoverMsg] = useState<string>('');

  useEffect(() => {
    if (existingCredit) {
      if (_.isNumber(existingCredit.totalAmount)) {
        setOverallDebt(existingCredit.totalAmount);
      }
      if (_.isNumber(existingCredit.monthlyRate)) {
        setMonthlyRate(existingCredit.monthlyRate);
      }
      if (existingCredit.statementTruthful !== undefined) {
        setConsentCheck(existingCredit.statementTruthful);
      }
      if (existingCredit.paymentProblem !== undefined) {
        setRegularPaymentsCheck(existingCredit.paymentProblem);
      }
    }
  }, [existingCredit]);

  const handleConsentChange = (event: ChangeEvent<HTMLInputElement>) => {
    const elementId = event.target.id;

    if (elementId === CONSENT_CHECK_ID) {
      setConsentCheck(!consentCheck);
    } else if (elementId === PAYMENTS_CHECK_ID) {
      setRegularPaymentsCheck(!regularPaymentsCheck);
    }
  };

  const handleDebtChange = (elementId: string) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = _.isNaN(parseFloat(event.target.value))
      ? 0
      : _.round(parseFloat(event.target.value), 2);
    if (elementId === OVERALL_DEBT_ID) {
      setOverallDebt(value);
    } else if (elementId === MONTHLY_DEBT_ID) {
      setMonthlyRate(value);
    }
  };

  useEffect(() => {
    if (
      consentCheck &&
      regularPaymentsCheck &&
      overallDebt !== null &&
      monthlyRate !== null
    ) {
      setNextButtonActive(true);
      return;
    }
    setNextButtonActive(false);
    setButtonHoverMsg(
      'Molimo ispunite kreditna opterećenja i prihvatite izjavu'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentCheck, regularPaymentsCheck, overallDebt, monthlyRate]);

  const handleNextPress = async (): Promise<boolean> => {
    const body = {
      credit_currency: 'HRK',
      credit_monthly_rate: monthlyRate,
      credit_name: 'Postojeći kredit',
      credit_repayment_months: null,
      credit_total: overallDebt,
      credit_statement_truthful: consentCheck,
      credit_payment_problem: regularPaymentsCheck,
    };

    try {
      await userApiRequest({
        method: ApiMethod.PUT,
        body,
      });
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <OnboardingPageContainer
      title="Kreditno opterećenje"
      buttonHoverMsg={buttonHoverMsg}
      nextButtonActive={nextButtonActive}
      onNextPress={handleNextPress}
    >
      <Box mb={3}>
        <Typography>
          Za nastavak podizanja kredita potrebni su nam podaci o Vašim ukupnim i
          mjesečnim kreditnim opterećenjima. Ukupno kreditno opterećenje
          predstavlja ukupnu sumu svih Vaših aktivnih kreditnih obaveza, dok
          mjesečno kreditno opterećenje predstavlja ukupnu mjesečnu ratu za
          njih.
        </Typography>
      </Box>

      <Box mb={3}>
        <FormControl>
          <TextField
            label="Ukupno kreditno opterećenje"
            id={OVERALL_DEBT_ID}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Kn</InputAdornment>
              ),
              inputProps: { min: 0.0, step: 0.01 },
              inputComponent: NumberFormatInput as any,
            }}
            variant="outlined"
            value={_.toString(overallDebt)}
            onChange={handleDebtChange(OVERALL_DEBT_ID)}
          />
        </FormControl>
      </Box>
      <Box mb={3}>
        <FormControl>
          <TextField
            label="Mjesečno kreditno opterećenje"
            id={MONTHLY_DEBT_ID}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Kn</InputAdornment>
              ),
              inputProps: { min: 0, step: 0.01 },
              inputComponent: NumberFormatInput as any,
            }}
            variant="outlined"
            value={_.toString(monthlyRate)}
            onChange={handleDebtChange(MONTHLY_DEBT_ID)}
          />
        </FormControl>
      </Box>

      <Box mb={3}>
        <Typography variant="h5">Prihvaćanje odgovornosti</Typography>
      </Box>

      <Box mb={3}>
        <FormControl component="fieldset">
          <FormControlLabel
            control={
              <Checkbox
                id={CONSENT_CHECK_ID}
                checked={consentCheck || false}
                color="primary"
                onChange={handleConsentChange}
              />
            }
            label="Korisnik prihvaća izjavu o točnosti i istinitosti navedenih podataka"
          />
          <FormControlLabel
            control={
              <Checkbox
                id={PAYMENTS_CHECK_ID}
                checked={regularPaymentsCheck || false}
                color="primary"
                onChange={handleConsentChange}
              />
            }
            label="Korisnik potvrđuje da je uredan u otplati vlastitih kreditnih obveza"
          />
        </FormControl>
      </Box>

      <Box mb={3}>
        <Typography>
          Prihvaćanjem izjave o točnosti i istinitosti navedenih podataka
          korisnik pod punom moralnom odgovornošću potvrđuje da su svi navedeni
          podaci za kreditna opterećenja točni.
        </Typography>
      </Box>
    </OnboardingPageContainer>
  );
};

DebitPage.route = ROUTES.debit;

export default DebitPage;
