import { State } from 'app';
import { isLoading, isEmpty } from 'api';

export const getCreditworthiness = (state: State) => state.creditworthiness;

export const getCreditworthinessLoading = (state: State): boolean => {
  return isLoading(getCreditworthiness(state));
};

export const getCreditworthinessEmpty = (state: State): boolean => {
  return isEmpty(getCreditworthiness(state));
};
