import React from 'react';
import NumberFormat from 'react-number-format';

import { NumberFormatLabelCmp, NumberFormatLabelProps } from './types';
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR } from './const';

const NumberFormatLabel: NumberFormatLabelCmp = (
  props: NumberFormatLabelProps
) => {
  const { unit, value, withDecimal = false } = props;

  return (
    <>
      <NumberFormat
        value={value}
        displayType={'text'}
        decimalSeparator={DECIMAL_SEPARATOR}
        thousandSeparator={THOUSAND_SEPARATOR}
        decimalScale={2}
        fixedDecimalScale={withDecimal}
        isNumericString={true}
      />
      &nbsp;{unit}
    </>
  );
};

export default NumberFormatLabel;
