import { IconNames } from 'theme';

const CARD_WIDTH = 204;

const CARD_HEIGHT = 216;

const ID_TO_NAME_MAP: { [key: number]: IconNames } = {
  7: IconNames.Mortgage,
  11: IconNames.Wallet,
  13: IconNames.Leasing,
  15: IconNames.EducationalSavings,
  17: IconNames.HouseSaving,
  1: IconNames.CreditCards,
  3: IconNames.MoneyBag,
  5: IconNames.Diamonds,
};

export { CARD_WIDTH, CARD_HEIGHT, ID_TO_NAME_MAP };
