export interface ApiConfig {
  path: string;
  method: ApiMethod;
  body?: any;
  headers?: any;
}

export interface UserApiConfig {
  body: any;
  method: ApiMethod;
}

export interface FileUploadApiConfig {
  path: string;
  files: File[];
}

export interface GenerateReportApiConfig {
  body: any;
}

export enum ApiMethod {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  DELETE = 'DELETE'
}
