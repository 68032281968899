import { State } from 'app';
import { isEmpty, isLoading } from 'api';

export const getUser = (state: State) => state.user;

export const getUserLoading = (state: State): boolean => {
  return isLoading(getUser(state));
};

export const getUserEmpty = (state: State): boolean => {
  return isEmpty(getUser(state));
};

export const getIsUserMarried = (state: State) => getUser(state).isMarried;

export const getUserFullName = (state: State) =>
  `${getUser(state).firstName} ${getUser(state).lastName}`;

export const getSpouse = (state: State) => getUser(state).spouse;

export const getSpouseFullName = (state: State) => {
  const spouse = getSpouse(state);
  if (spouse) {
    return `${spouse.firstName} ${spouse.lastName}`;
  }
  return undefined;
};

export const getRequestedCredit = (state: State) => {
  return state.user.requestedCredit;
};

export const getExistingCredit = (state: State) => {
  return state.user.existingCredit;
};

export const getLastOnboardingStepId = (state: State) => {
  return state.user.lastOnboardingStepId;
}
