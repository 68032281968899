import { createApiReducer, chainReducers, INITIAL_API_STATUS } from 'api';
import { UserActionTypes } from './actions';

import { CreditWorthiness, CompanyDescription } from '../types';
import { CreditWorthinessState } from './types';

const initialCompanyInfo: CompanyDescription = {
  identificationNumberCompany: null,
  identificationNumber: null,
  nkd: null,
  oib: null,
  bankruptcy: null,
  countyId: null,
  dateAndPlaceOfFoundation: null,
  id: null,
  nkdId: null,
  fax: null,
  email: null,
  dateOfDeactivation: null,
  liquidation: null,
  website: null,
  isActive: null,
  originOfCapital: null,
  telephone: null,
  blockadeStatus: null,
  legalStructure: null,
  numberOfEmployees: null,
  fullName: null,
  isMemberOfHgk: null,
  location: null,
  shortName: null,
  mobileNumber: null,
  countryId: null,
  companySize: null,
}

const initialState: CreditWorthinessState = {
  _status: INITIAL_API_STATUS,
  client: {
    companyDescription: initialCompanyInfo,
    companyAssetsCapital: [],
  },
  spouse: {
    companyDescription: initialCompanyInfo,
    companyAssetsCapital: [],
  }
};

const transformDescription = (description: any):CompanyDescription => ({
  identificationNumberCompany: description.identification_number_company,
  identificationNumber: description.identification_number,
  nkd: description.nkd,
  oib: description.oib,
  bankruptcy: description.bankruptcy,
  countyId: description.county_id,
  dateAndPlaceOfFoundation: description.date_and_place_of_foundation,
  id: description.id,
  nkdId: description.nk_d__id,
  fax: description.fax,
  email: description.email,
  dateOfDeactivation: description.date_of_deactivation,
  liquidation: description.liquidation,
  website: description.website,
  isActive: description.is_active,
  originOfCapital: description.origin_of_capital,
  telephone: description.telephone,
  blockadeStatus: description.blockade_status,
  legalStructure: description.legal_struture,
  numberOfEmployees: description.number_of_employees,
  fullName: description.full_name,
  isMemberOfHgk: description.is_member_of_h_g_k,
  location: description.location,
  shortName: description.short_name,
  mobileNumber: description.mobile_number,
  countryId: description.country_id,
  companySize: description.company_size,
})

const transformCreditWorthiness = (response: any): CreditWorthiness => {
  const { data } = response;
  const clientCompanyAssetsCapital = data.clientCompanyAssetsCapital;
  const spouseCompanyAssetsCapital = data.spouseCompanyAssetsCapital;
  const clientCompanyDesc = transformDescription(data.clientCompanyDesc[0]);
  const spouseCompanyDesc = transformDescription(data.spouseCompanyDesc[0]);
  return {
    client: {
      companyDescription: clientCompanyDesc,
      companyAssetsCapital: clientCompanyAssetsCapital
    },
    spouse: {
      companyDescription: spouseCompanyDesc,
      companyAssetsCapital: spouseCompanyAssetsCapital
    }
  }
};

const fetchUser = createApiReducer(
  [
    UserActionTypes.FETCH_CREDITWORTHINESS_START,
    UserActionTypes.FETCH_CREDITWORTHINESS_SUCCESS,
    UserActionTypes.FETCH_CREDITWORTHINESS_FAILURE,
  ],
  transformCreditWorthiness,
  data => data
);

export default chainReducers(initialState, fetchUser);
