import { createApiAction } from 'api';
import { StartAction, SuccessAction, FailureAction } from 'api/redux/types'; // todo fix this importing

export enum FormDataActionTypes {
  FETCH_FORM_DATA_START = 'form_data/fetch_start',
  FETCH_FORM_DATA_SUCCESS = 'form_data/fetch_success',
  FETCH_FORM_DATA_FAILURE = 'form_data/fetch_failure',
}

const fetchUserStart = (): StartAction => ({
  type: FormDataActionTypes.FETCH_FORM_DATA_START,
  payload: null,
});

const fetchUserSuccess = (payload: any /*TODO*/): SuccessAction => ({
  type: FormDataActionTypes.FETCH_FORM_DATA_SUCCESS,
  payload,
});

const fetchUserFailure = (error: any /*TODO*/): FailureAction => ({
  type: FormDataActionTypes.FETCH_FORM_DATA_FAILURE,
  payload: { error },
});

export const fetchFormData = (/*TODO*/) =>
  createApiAction(
    {
      path: '/ccr/fe/formData.json',
      method: 'GET',
    },
    fetchUserStart,
    fetchUserSuccess,
    fetchUserFailure
  );
