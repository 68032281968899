import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Divider,
  Typography,
  Paper,
  Link,
  LinearProgress,
} from '@material-ui/core';

import { fetchUser, getUser, getUserEmpty } from 'user';
import { fetchFormData } from 'formData';
import { OnboardingRouter, OnboardingWrapper } from 'onboarding';
import { ROUTES } from 'routes';

import { AppProgress } from '../AppProgress';
import { AppTitle } from '../AppTitle';

import { useAppHomeStyles } from './styled';
import { AppHomeCmp } from './types';

const AppHome: AppHomeCmp = (props: any) => {
  const classes = useAppHomeStyles(props);
  const user = useSelector(getUser);
  const isUserEmpty = useSelector(getUserEmpty);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchFormData());
  }, [dispatch]);

  useEffect(() => {
    setIsLoaderVisible(isUserEmpty);
  }, [isUserEmpty]);

  const refreshPage = () => {
    window.location.reload(false);
  };

  const renderInstructions = () => {
    return (
      <Paper className={classes.contentMargin}>
        <Grid container direction="column">
          <Grid
            item
            container
            direction="column"
            className={classes.largeBottomPadding}
          >
            {/* We can add bottom spacing with classnames or Box component */}
            <Grid item className={classes.smallBottomPadding}>
              <Typography variant="h4">Problem s učitavanjem eOI</Typography>
            </Grid>
            <Grid>
              <Divider />
            </Grid>
          </Grid>
          <Grid item className={classes.largeBottomPadding}>
            <Typography>
              Za više informacija o korištenju eOI možete pronaći na službenim
              stranicama <Link href="https://www.eid.hr/hr/eoi">eOI</Link>
            </Typography>
          </Grid>
          <Grid item className={classes.smallBottomPadding}>
            <Typography>
              Molimo pokušajte ponovo učitati svoju eOI i pokrenite Reputator.
            </Typography>
          </Grid>
          <Grid>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={refreshPage}
            >
              Pokreni reputator
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const renderOnboarding = () => {
    return (
      <>
        <Grid item>
          <AppProgress />
        </Grid>
        <Grid item>
          <OnboardingWrapper>
            <OnboardingRouter />
          </OnboardingWrapper>
        </Grid>
      </>
    );
  };

  if (isLoaderVisible) {
    return <LinearProgress variant="indeterminate" />;
  }

  return (
    <div className={classes.homeWrapper}>
      <Grid container direction="column">
        <Grid item>
          <AppTitle />
        </Grid>
        {user.id ? renderOnboarding() : renderInstructions()}
      </Grid>
    </div>
  );
};

AppHome.route = ROUTES.appHome;

export default AppHome;
