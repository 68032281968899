import { State } from 'app';
import { isLoading, isEmpty } from 'api';

export const getCreditOptions = (state: State) => state.creditOptions;

export const getCreditOptionsLoading = (state: State): boolean => {
  return isLoading(getCreditOptions(state));
};

export const getCreditOptionsEmpty = (state: State): boolean => {
  return isEmpty(getCreditOptions(state));
};
