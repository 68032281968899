import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Redirect } from 'react-router';
import { LinearProgress } from '@material-ui/core';

import { getLastOnboardingStepId, getUserEmpty, getUserLoading } from 'user';
import { ROUTES } from 'routes';
import { setCurrentOnboardingStepId } from 'onboarding';

import { OnboardingWrapperCmp, OnboardingWrapperProps } from './types';
import { getOnboardingStepIdFromUrl } from './utils';

const OnboardingWrapper: OnboardingWrapperCmp = (
  props: OnboardingWrapperProps
) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const isUserEmpty = useSelector(getUserEmpty);
  const isUserLoading = useSelector(getUserLoading);
  const lastOnboardingStepId = useSelector(getLastOnboardingStepId);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);

  useEffect(() => {
    setIsLoaderVisible(isUserEmpty);
  }, [isUserEmpty]);

  if (isLoaderVisible) {
    return <LinearProgress variant="indeterminate" />;
  }

  const { pathname } = location;
  const currentOnboardingStepId = getOnboardingStepIdFromUrl(pathname);

  const shouldRedirect =
    !isUserLoading &&
    !!currentOnboardingStepId &&
    currentOnboardingStepId > lastOnboardingStepId;

  const { children } = props;

  if (!shouldRedirect && currentOnboardingStepId) {
    dispatch(setCurrentOnboardingStepId(currentOnboardingStepId));
  }

  return shouldRedirect ? <Redirect to={ROUTES.appHome} /> : <>{children}</>;
};

export default OnboardingWrapper;
