import { State } from 'app';
import { isLoading, isEmpty } from 'api';

import { Loan } from '../types';

export const getFormData = (state: State) => state.formData;

export const getLoans = (state: State): Loan[] => {
  const fromData = getFormData(state);
  return fromData.loans;
};


/**
 * Gets loan by `id`
 * @param id
 */
export const getLoan = (id: number) => (state: State) => {
  const formData = getFormData(state);
  const loanIndex = formData.loans.findIndex(l => l.id === id);
  return formData.loans[loanIndex];
};

export const getFromDataLoading = (state: State): boolean => {
  return isLoading(getFormData(state));
};

export const getFromDataEmpty = (state: State): boolean => {
  return isLoading(isEmpty(state));
};
