import React from 'react';
import { Typography, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { InfoItemCmp, InfoItemProps } from './types';
import { useFontStyle } from './styled';

function valueResolver(value: null | string | boolean | number | undefined) {
  switch (typeof value) {
    case 'number':
    case 'string': {
      return value;
    }
    case 'boolean': {
      return value ? 'DA' : 'NE';
    }
    case 'object':
    case 'undefined': {
      return 'Nema podatka'
    }
  } 
}

const InfoItem: InfoItemCmp = ({ title, value, isLoading }: InfoItemProps) => {
  const classes = useFontStyle();
  const resolvedValue = valueResolver(value);

  return (
    <Box display="flex" flexShrink="shrink">
      <Box maxWidth={156} minWidth={156} mr={1}>
        {isLoading ? (
          <Skeleton height={24} width="100%" style={{ margin: 0 }} />
        ) : (
          <Typography className={classes.bold}>{title}</Typography>
        )}
      </Box>
      <Box maxWidth={376} minWidth={376}>
        {isLoading ? (
          <Skeleton height={24} width="100%" style={{ margin: 0 }} />
        ) : (
          <Typography>{resolvedValue}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default InfoItem;
