import React from 'react';
import { Breadcrumbs, Typography, Box } from '@material-ui/core';
import { useLocation } from 'react-router';
import _ from 'lodash';

import {
  ROUTES,
  ONBOARDING_ROUTE_NAMES,
  RoutesKeys,
  ONBOARDING_ROUTE_INDEX,
} from 'routes';

import { useBreadcrumbStyles, NoStyleLink } from './styled';
import { AppProgressCmp } from './types';
import { useSelector, useDispatch } from 'react-redux';
import { getLastOnboardingStepId, fetchUser } from 'user';
import { AppForget } from '../AppForget';

const AppProgress: AppProgressCmp = props => {
  const lastOnboardingStepId = useSelector(getLastOnboardingStepId);
  const classes = useBreadcrumbStyles(props);
  const location = useLocation();

  const { pathname } = location;

  const dispatch = useDispatch();

  const fetchUserInfo = () => {
    dispatch(fetchUser());
  }

  const checkIsStepFinished = (routeKey: RoutesKeys): boolean => {
    const pathId = ONBOARDING_ROUTE_INDEX[routeKey];
    return pathId <= lastOnboardingStepId;
  };

  const resolveClasses = (routeKey: RoutesKeys) => {
    if (pathname === ROUTES[routeKey]) {
      return classes.activeStep;
    }

    if (checkIsStepFinished(routeKey)) {
      return classes.finishedStep;
    }

    return '';
  };

  return (
    <Box style={{ display: 'flex', justifyContent: 'space-between' }} className={classes.bottomPadding}>
      <Breadcrumbs maxItems={10}>
        {_.toPairs(ONBOARDING_ROUTE_NAMES).map(
          (pair: [string, string], index: number) => {
            const [routeKey, routeName] = pair;
            const resolvedClasses = resolveClasses(routeKey as RoutesKeys);
            const isStepFinished = checkIsStepFinished(routeKey as RoutesKeys);
            return isStepFinished ? (
              <NoStyleLink
                to={ROUTES[routeKey as RoutesKeys]}
                key={`bredcrumbLink${index}`}
                onClick={fetchUserInfo}
              >
                <Typography variant="subtitle1" className={resolvedClasses}>
                  {routeName}
                </Typography>
              </NoStyleLink>
            ) : (
              <Typography
                variant="subtitle1"
                className={resolvedClasses}
                key={`bredcrumbLink${index}`}
              >
                {routeName}
              </Typography>
            );
          }
        )}
      </Breadcrumbs>
      <AppForget />
    </Box>
  );
};

export default AppProgress;
