import { createApiReducer, chainReducers, INITIAL_API_STATUS } from 'api';
import { FormDataActionTypes } from './actions';

import { FormData, Loan, LoanDocument } from '../types';
import { FormDataState } from './types';

const initialState: FormDataState = {
  loans: [],
  _status: INITIAL_API_STATUS,
};

const extractDocument = (document: any): LoanDocument => ({
  mandatory: document.mandatory_doc,
  partnerMandatory: document.partner_mandatory_doc,
  typeId: document.codebook_doc_type_id,
  name: document.codebook_doc_type_name,
  label: document.codebook_doc_type_label,
  description: document.codebook_doc_type_desc,
});

const extractLoan = (loan: any): Loan => ({
  id: loan.id,
  type: loan.type,
  group: loan.group,
  description: loan.desc,
  documents: loan.documents.user.map(extractDocument),
});

const transformFormData = (response: any): FormData => {
  const formData = response.data.form_data;
  const loans = formData.loans.map(extractLoan);
  return { loans };
};

const fetchFormData = createApiReducer(
  [
    FormDataActionTypes.FETCH_FORM_DATA_START,
    FormDataActionTypes.FETCH_FORM_DATA_SUCCESS,
    FormDataActionTypes.FETCH_FORM_DATA_FAILURE,
  ],
  transformFormData,
  data => data
);

export default chainReducers(initialState, fetchFormData);
