/* eslint-disable */
import React, { useEffect, useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';

import { getUserApiRequest, ApiMethod } from 'api';
import { ConsentType, getFromDataEmpty, getFromDataLoading } from 'formData';
import { ROUTES } from 'routes';
import { getUser } from 'user';

import { OnboardingPageCmp } from '../../types';
import { OnboardingPageContainer } from '../../components';
import {
  CHECKBOX_SKELETON_HEIGHT,
  CHECKBOX_SKELETON_WIDTH,
  consentTypes,
} from './const';
import { getConsentsArrayFromUser } from './utils';

const WelcomePage: OnboardingPageCmp = () => {
  const user = useSelector(getUser);
  const isFromDataLoading = useSelector(getFromDataLoading);
  const isFromDataEmpty = useSelector(getFromDataEmpty);
  const userApiRequest = useSelector(getUserApiRequest);

  const [selectedBullets, setSelectedBullets] = useState<number[]>([]);
  const [isFormValid, setFormValidation] = useState(false);
  const [nextButtonActive, setNextButtonActive] = useState<boolean>(false);
  const [buttonHoverMsg, setButtonHoverMsg] = useState<string>('');

  useEffect(() => {
    setSelectedBullets(getConsentsArrayFromUser(user));
  }, [user]);

  useEffect(() => {
    validate(selectedBullets);

    if (isFormValid) {
      setNextButtonActive(true);
      return;
    }

    setNextButtonActive(false);
    setButtonHoverMsg('Molimo označite obavezne uvjete korištenja');
  }, [selectedBullets, isFormValid]);

  const title = `Dobrodošli ${user.firstName} ${user.lastName}`;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const consentId = parseInt(event.target.value, 10);

    if (selectedBullets.includes(consentId)) {
      setSelectedBullets(selectedBullets.filter(id => id !== consentId));
    } else {
      setSelectedBullets([...selectedBullets, consentId]);
    }
  };

  const handleNextPress = async (): Promise<boolean> => {
    const body = consentTypes.reduce(
      (acc, c) => ({
        ...acc,
        [c.bodyName]: checkIfSelected(c.id),
      }),
      {}
    );
    try {
      await userApiRequest({
        method: ApiMethod.PUT,
        body,
      });
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkIfSelected = (id: number): boolean => {
    const found = selectedBullets.find(i => i === id);
    return !!found;
  };

  const validate = (selected: number[]) => {
    const existRequiredNonChecked = _.find(
      consentTypes,
      consent => consent.required && !_.includes(selected, consent.id)
    );

    setFormValidation(!existRequiredNonChecked);
  };

  const renderCheckbox = (consent: ConsentType) => {
    return (
      <FormControlLabel
        key={`consent-type-${consent.name}`}
        control={
          <Checkbox
            checked={selectedBullets.includes(consent.id)}
            color="primary"
            value={consent.id}
            onChange={handleChange}
          />
        }
        label={consent.description + (consent.required ? ' *' : '')}
      />
    );
  };

  const renderRealData = () => {
    return (
      <FormControl component="fieldset">
        {consentTypes.map(renderCheckbox)}
        <FormHelperText>* Obavezni uvjeti korištenja</FormHelperText>
      </FormControl>
    );
  };

  const renderSkeletons = () => {
    return [...new Array(4)].map((item, i) => (
      <Box key={`skeleton${i}`} mb={2}>
        <Skeleton
          height={CHECKBOX_SKELETON_HEIGHT}
          width={CHECKBOX_SKELETON_WIDTH}
        />
      </Box>
    ));
  };

  const shouldLoadSkeleton = isFromDataLoading || isFromDataEmpty;

  return (
    <OnboardingPageContainer
      title={title}
      buttonHoverMsg={buttonHoverMsg}
      nextButtonActive={nextButtonActive}
      onNextPress={handleNextPress}
    >
      <Box mb={3}>
        <Typography>
          Molimo pročitajte sve navedene Uvjete korištenja i označite sva s
          kojima se slažete.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h5">Prihvaćanje uvjeta korištenja</Typography>
      </Box>

      <Box mb={3}>
        {shouldLoadSkeleton ? renderSkeletons() : renderRealData()}
      </Box>

      <Box mb={3}>
        <Typography>
          Koštenjem usluga Reputatora smatra se da su korisnici u svakom
          trenutku upoznati s ovim Uvjetima korištenja, te da ih u potpunosti
          razumiju i prihvaćaju.
        </Typography>
      </Box>
    </OnboardingPageContainer>
  );
};

WelcomePage.route = ROUTES.welcome;

export default WelcomePage;
