import { APP_MAX_WIDTH } from 'app';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const usePageContainerStyles = makeStyles((theme: Theme) => ({
  buttonsRow: {
    'max-width': APP_MAX_WIDTH,
  },
  stickToBottom: {
    position: 'sticky',
    bottom: '0px',
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing() * 2,
    paddingBottom: theme.spacing() * 2,
    display: 'flex',
    justifyContent: 'center',
  },
}));
