import React from 'react';
import { Grid, Typography, Divider, Paper } from '@material-ui/core';

import { AppButtons } from '../AppButtons';

import {
  OnboardingPageContainerCmp,
  OnboardingPageContainerProps,
} from './types';
import { usePageContainerStyles } from './styled';

const OnboardingPageContainer: OnboardingPageContainerCmp = ({
  title,
  children,
  buttonHoverMsg,
  nextButtonActive,
  ...otherProps
}: OnboardingPageContainerProps) => {
  const classes = usePageContainerStyles();

  const renderAppContent = () => (
    <Paper className={classes.contentMargin}>
      <Grid container direction="column">
        <Grid
          item
          container
          direction="column"
          className={classes.largeBottomPadding}
        >
          {/* We can add bottom spacing with classnames or Box component */}
          <Grid item className={classes.smallBottomPadding}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <Grid>
            <Divider />
          </Grid>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Paper>
  );

  return (
    <>
      {renderAppContent()}
      <AppButtons
        buttonHoverMsg={buttonHoverMsg || 'placeholder msg'}
        nextButtonActive={!!nextButtonActive}
        {...otherProps}
      />
    </>
  );
};

export default OnboardingPageContainer;
