import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { NumberFormatLabel } from '..';
import { CreditPreviewCmp, CreditPreviewProps, SkeletonCmp } from './types';
import { SKELETON_HEIGHT, SKELETON_WIDTH } from './const';
import {
  calculateMonthlyPaymentAmount,
  calculateOverallAmount,
  calculateInterestAmount,
} from '../../utils/utils';

const CreditPreview: CreditPreviewCmp = (props: CreditPreviewProps) => {
  const {
    amount,
    numberOfPayments,
    interestRate,
    currency,
    durationUnit,
    maxMonthlyPayment,
    onValidation,
  } = props;

  const monthlyPayment = calculateMonthlyPaymentAmount(
    amount,
    interestRate,
    numberOfPayments
  );

  const overallAmount = calculateOverallAmount(
    monthlyPayment,
    numberOfPayments
  );
  const interestAmount = calculateInterestAmount(amount, overallAmount);

  const validate = () => {
    let validationResult = true;
    if (maxMonthlyPayment) {
      validationResult = monthlyPayment <= maxMonthlyPayment;
    }

    onValidation(validationResult);
    return validationResult;
  }

  const isSelectedCreditValid = validate();

  return (
    <Box mb={3}>
      <Grid container spacing={1}>
        <Grid item md={2}>
          <Box fontWeight="fontWeightBold">Iznos kredita:</Box>
          <Box fontWeight="fontWeightBold">Procjenjena kamata:</Box>
          <Box fontWeight="fontWeightBold">Ukupan iznos kredita:</Box>
        </Grid>
        <Grid item md={4}>
          <Box>
            <NumberFormatLabel value={amount} unit={currency} />
          </Box>
          <Box>
            <NumberFormatLabel
              value={interestRate * 100}
              unit="%"
              withDecimal
            />
          </Box>
          <Box>
            <NumberFormatLabel
              value={overallAmount}
              unit={currency}
              withDecimal
            />
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box fontWeight="fontWeightBold">Duljina otplate:</Box>
          <Box fontWeight="fontWeightBold" style={{ color: isSelectedCreditValid ? '' : 'red'}}>Mjesečni iznos:</Box>
          {maxMonthlyPayment && (
            <Box fontWeight="fontWeightBold">
              Maksimalni mogući mjesečni iznos:
            </Box>
          )}
          <Box fontWeight="fontWeightBold">Ukupan iznos kamata:</Box>
        </Grid>
        <Grid item md={3}>
          <Box>
            {numberOfPayments} {durationUnit}
          </Box>
          <Box>
            <NumberFormatLabel
              value={monthlyPayment}
              unit={currency}
              withDecimal
            />
          </Box>
          {maxMonthlyPayment && (
            <Box>
              <NumberFormatLabel
                value={maxMonthlyPayment}
                unit={currency}
                withDecimal
              />
            </Box>
          )}
          <Box>
            <NumberFormatLabel
              value={interestAmount}
              unit={currency}
              withDecimal
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const CreditCalculatorSkeleton: SkeletonCmp = () => {
  return (
    <Box mb={3}>
      <Grid container spacing={1}>
        <Grid item md={7}>
          <Skeleton height={SKELETON_HEIGHT} width={SKELETON_WIDTH} />
        </Grid>
        <Grid item md={5}>
          <Skeleton height={SKELETON_HEIGHT} width={SKELETON_WIDTH} />
        </Grid>
      </Grid>
    </Box>
  );
};

CreditPreview.Skeleton = CreditCalculatorSkeleton;

export default CreditPreview;
