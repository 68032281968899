import {
  OnboardingActionTypes,
  OnboardingState,
  SET_CURRENT_ONBOARDING_STEP_ID,
  INCREASE_CURRENT_ONBOARDING_STEP_ID,
} from './types';

const initialState: OnboardingState = {
  currentStepId: 0,
};

const onboardingReducer = (
  state = initialState,
  action: OnboardingActionTypes
): OnboardingState => {
  switch (action.type) {
    case SET_CURRENT_ONBOARDING_STEP_ID:
      return {
        ...state,
        currentStepId: action.payload.currentStepId,
      };
    case INCREASE_CURRENT_ONBOARDING_STEP_ID:
      return {
        ...state,
        currentStepId: state.currentStepId + 1,
      };
    default:
      return state;
  }
};

export default onboardingReducer;
