import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/CloudDownload';

import { getApiRequest, ApiMethod } from 'api';
import { ROUTES } from 'routes';
import { getUser } from 'user';

import { OnboardingPageCmp } from '../../types';
import { OnboardingPageContainer } from '../../components';

const CreditWorthinessPage: OnboardingPageCmp = () => {
  const user = useSelector(getUser);
  const apiRequest = useSelector(getApiRequest);

  const requestBody = { pid: user.id, report: 'ClientReport.jasper' };

  const handleGenerate = async () => {
    try {
      const response = await apiRequest({
        method: ApiMethod.POST,
        path: 'report/runReport.json',
        body: JSON.stringify(requestBody),
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data) {
        const linkSource = `data:application/pdf;base64,${response.data}`;
        const downloadLink = document.createElement('a');
        const today = new Date().toISOString().split('T')[0];
        const fileName = `${today}_report.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    } catch (e) {
      console.error('error run report', e);
    }
  };

  return (
    <OnboardingPageContainer title="Bonitet" nextButtonActive>
      <Box pb={2}>
        <Typography>
          Iz priloženih dokumenata i javno dostupnih informacija Reputator Vam
          nudi mogućnost generiranja kreditnog izvještaja.
        </Typography>
      </Box>

      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={handleGenerate}
        startIcon={<SaveIcon />}
      >
        Preuzmi kreditni izvještaj
      </Button>
    </OnboardingPageContainer>
  );
};

CreditWorthinessPage.route = ROUTES.creditworthiness;

export default CreditWorthinessPage;
