import React, { useState, SyntheticEvent } from 'react';
import { Typography, Radio, ButtonBase, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import classNames from 'classnames';
import { Icon } from 'components';

import { MoreInfoDialog } from '..';
import { useCardStyles } from './styled';
import { CreditCardCmp, CreditCardProps, SkeletonCmp } from './types';
import { CARD_HEIGHT, CARD_WIDTH, ID_TO_NAME_MAP } from './const';

const CreditCard: CreditCardCmp = (props: CreditCardProps) => {
  const classes = useCardStyles(props);
  const [dialogShown, setDialogShown] = useState(false);

  const { active, name, onClick, id, description } = props;
  const cardClassnames = classNames(classes.cardContainer, {
    [classes.cardActive]: active,
  });

  const handleCardClick = () => {
    if (!onClick) {
      return;
    }
    onClick(id);
  };
  const handleDialogShow = (e: SyntheticEvent) => {
    e.stopPropagation();
    setDialogShown(true);
  };
  const handleDialogHide = (e: SyntheticEvent) => {
    e.stopPropagation();
    setDialogShown(false);
  };

  return (
    <ButtonBase className={cardClassnames} onClick={handleCardClick}>
      {!!onClick && (
        <Radio
          checked={active}
          disableRipple
          color="primary"
          className={classes.radio}
        />
      )}
      <InfoIcon className={classes.infoIcon} onClick={handleDialogShow} />
      <MoreInfoDialog
        title={name}
        text={description}
        open={dialogShown}
        onClose={handleDialogHide}
      />
      <Box>
        <Icon name={ID_TO_NAME_MAP[id]} className={classes.creditIcon} />
        <Box mt={1}>
          <Typography>{name}</Typography>
        </Box>
      </Box>
    </ButtonBase>
  );
};

const CreditCardSkeleton: SkeletonCmp = () => {
  return <Skeleton height={CARD_HEIGHT} width={CARD_WIDTH} />;
};

CreditCard.Skeleton = CreditCardSkeleton;

export default CreditCard;
