import { Paper as MaterialPaper } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import styled from 'styled-components';

/*
 * Extends default Material Paper by adding ability to add spacing
 * Default spacing set to 5 * 8 on all sides
 */
export const Paper = styled(MaterialPaper)`
  ${spacing}
`;

Paper.defaultProps = {
  p: 5,
};
