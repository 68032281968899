import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Grid, Button, Fade } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { ROUTES, ONBOARDING_ROUTE_INDEX, RoutesKeys } from 'routes';
import { getUserApiRequest, ApiMethod } from 'api';
import { getLastOnboardingStepId, fetchUser } from 'user';
import { getCurrentOnboardingStepId } from 'onboarding';

import WarningTooltip from './WarningTooltip';
import { AppButtonsCmp, AppButtonsProps } from './types';
import { usePageContainerStyles } from './styled';

const AppButtons: AppButtonsCmp = (props: AppButtonsProps) => {
  const history = useHistory();

  const userApiRequest = useSelector(getUserApiRequest);
  const lastOnboardingStepId = useSelector(getLastOnboardingStepId);
  const currentOnboardingStepId = useSelector(getCurrentOnboardingStepId);

  const dispatch = useDispatch();

  const {
    buttonHoverMsg,
    nextButtonActive,
    previousButtonActive,
    onBackPress,
    onNextPress,
    ...otherProps
  } = props;

  const isFirstStep = currentOnboardingStepId !== 1;

  const isLastStep =
    currentOnboardingStepId &&
    // division by 2 because:
    // https://stackoverflow.com/questions/38034673/determine-the-number-of-enum-elements-typescript
    currentOnboardingStepId === _.keys(ONBOARDING_ROUTE_INDEX).length / 2;
  function handleButtonBack() {
    if (onBackPress) {
      onBackPress();
    }

    dispatch(fetchUser());

    const nextStepKey = _.findKey(
      ONBOARDING_ROUTE_INDEX,
      v => v === currentOnboardingStepId - 1
    );

    history.push(ROUTES[nextStepKey as RoutesKeys]);
  }

  async function handleButtonForward() {
    if (onNextPress) {
      const result = await onNextPress();
      if (!result) {
        return;
      }
    }

    const nextStepKey = _.findKey(
      ONBOARDING_ROUTE_INDEX,
      v => v === currentOnboardingStepId + 1
    );

    if (currentOnboardingStepId === lastOnboardingStepId) {
      try {
        await updateCurrentStepId();
      } catch (e) {
        // tslint:disable-next-line: no-console
        console.log(e);
        return;
      }
    }

    history.push(ROUTES[nextStepKey as RoutesKeys]);
  }

  const updateCurrentStepId = async () => {
    await userApiRequest({
      method: ApiMethod.PUT,
      body: {
        last_onboarding_step_id: currentOnboardingStepId + 1,
      },
    });
    dispatch(fetchUser());
  };

  const classes = usePageContainerStyles();
  const isPreviousButtonActive =
    previousButtonActive === undefined ? true : previousButtonActive;

  return (
    <div className={classes.stickToBottom}>
      <Grid container justify="space-between" {...otherProps}>
        <Grid item>
          <Fade in={!!isFirstStep}>
            <Button
              size="large"
              variant="outlined"
              onClick={handleButtonBack}
              disabled={!isPreviousButtonActive}
            >
              Prethodni Korak
            </Button>
          </Fade>
        </Grid>
        <WarningTooltip
          title={buttonHoverMsg}
          placement="left"
          disableHoverListener={nextButtonActive || isLastStep === true}
        >
          <Grid item>
            <Fade in={!isLastStep}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleButtonForward}
                disabled={!nextButtonActive}
              >
                Sljedeći Korak
              </Button>
            </Fade>
          </Grid>
        </WarningTooltip>
      </Grid>
    </div>
  );
};

export default AppButtons;
