import { CreditPeriodUnit } from 'credit-options';

export const getPeriodUnitLabel = (
  unit: CreditPeriodUnit,
  duration?: number
) => {
  if (!duration || duration === 1) {
    return unit === CreditPeriodUnit.MONTH ? 'mjesec' : 'godina';
  }
  if (duration > 1 && duration < 5) {
    return unit === CreditPeriodUnit.MONTH ? 'mjeseca' : 'godine';
  }

  return unit === CreditPeriodUnit.MONTH ? 'mjeseci' : 'godina';
};

export const calculateMonthlyPaymentAmount = (
  amount: number,
  interestRate: number,
  numberOfPayments: number
) => {
  const interestFactor = 1 + interestRate / 12;

  let monthlyPayment =
    (amount *
      Math.pow(interestFactor, numberOfPayments) *
      (interestFactor - 1)) /
    (Math.pow(interestFactor, numberOfPayments) - 1);

  monthlyPayment = Math.round(monthlyPayment * 100) / 100;

  return monthlyPayment;
};

export const calculateOverallAmount = (
  monthlyPayment: number,
  numberOfPayments: number
) => {
  return Math.round(monthlyPayment * numberOfPayments * 100) / 100;
};

export const calculateInterestAmount = (
  amount: number,
  overallAmount: number
) => {
  return Math.round((overallAmount - amount) * 100) / 100;
};
