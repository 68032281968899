import { createApiReducer, chainReducers, INITIAL_API_STATUS } from 'api';
import { UserActionTypes } from './actions';

import { CreditOptionsState } from './types';
import { CreditOptions } from '../types';

const initialState: CreditOptionsState = {
  _status: INITIAL_API_STATUS,
  currency: null,
  estimatedInterestRate: null,
  maxAmount: null,
  maxPeriod: null,
  maxRate: null,
  periodUnit: null,
  personalId: null,
};

const transformCreditOptions = (response: any): CreditOptions => ({
  currency: response.data.credit_currency,
  estimatedInterestRate: response.data.credit_estimated_interest_rate,
  maxAmount: response.data.credit_max_amount,
  maxPeriod: response.data.credit_max_period,
  maxRate: response.data.credit_max_rate,
  periodUnit: response.data.credit_period_unit,
  personalId: response.data.personal_id,
});

const fetchCreditOptions = createApiReducer(
  [
    UserActionTypes.FETCH_CREDIT_OPTIONS_START,
    UserActionTypes.FETCH_CREDIT_OPTIONS_SUCCESS,
    UserActionTypes.FETCH_CREDIT_OPTIONS_FAILURE,
  ],
  transformCreditOptions,
  data => data
);

export default chainReducers(initialState, fetchCreditOptions);
