import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';


const WarningTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'transparent',
    border: '1px solid',
    color: theme.palette.error.main,
    fontSize: 12,
  },
}))(Tooltip);

export default WarningTooltip;
