import { State } from 'app';

import {
  createApiRequest,
  createUserApiRequest,
  createFileUploadApiRequest,
  createGenerateReportApiRequest,
  createHwcryptoCreateContainerApiRequest,
  createHwcryptoGenerateHashApiRequest,
  createDownloadDocumentApiRequest,
  createForgetUserDataApiRequest,
} from '../requests';

export const getApiRequest = (state: State) => createApiRequest(state);
export const getUserApiRequest = (state: State) => createUserApiRequest(state);
export const getFileUploadApiRequest = (state: State) =>
  createFileUploadApiRequest(state);

export const getGenerateReportApiRequest = (state: State) =>
  createGenerateReportApiRequest(state);

export const getHwcryptoCreateContainerApiRequest = (state: State) =>
  createHwcryptoCreateContainerApiRequest(state);
export const getHwcryptoGenerateHashApiRequest = (state: State) =>
  createHwcryptoGenerateHashApiRequest(state);

export const getDownloadDocumentApiRequest = (state: State) =>
  createDownloadDocumentApiRequest(state);

export const getForgetUserDataApiRequest = (state: State) =>
  createForgetUserDataApiRequest(state);
