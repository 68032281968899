import {
  SetCurrentOnboardingStepIdAction,
  SET_CURRENT_ONBOARDING_STEP_ID,
  IncreaseCurrentOnboardingStepIdAction,
  INCREASE_CURRENT_ONBOARDING_STEP_ID,
} from './types';

export const setCurrentOnboardingStepId = (
  currentStepId: number
): SetCurrentOnboardingStepIdAction => ({
  type: SET_CURRENT_ONBOARDING_STEP_ID,
  payload: {
    currentStepId,
  },
});

export const increaseCurrentOnboardingStepId = (): IncreaseCurrentOnboardingStepIdAction => ({
  type: INCREASE_CURRENT_ONBOARDING_STEP_ID,
});
