import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import _ from 'lodash';

import { getUserApiRequest, ApiMethod } from 'api';

import { UserInfoCmp } from './types';
import { getUser } from 'user';

const EMAIL_INFO_ID = 'email-info';
const PHONE_INFO_ID = 'phone-info';

const UserInfo: UserInfoCmp = () => {
  const userApiRequest = useSelector(getUserApiRequest);
  const user = useSelector(getUser);

  const [userPhone, setUserPhone] = useState<string|null>(user.phone);
  const [userEmail, setUserEmail] = useState<string|null>(user.email);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (!initialRender) {
      debounceUpdateUserInfo(userEmail, userPhone);
    } else {
      setInitialRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail, userPhone]);

  const updateUserInfo = async (email: string | null, phone: string | null) => {
    const requestBody = {
      user_email: email,
      user_phone: phone,
    };

    await userApiRequest({
      method: ApiMethod.POST,
      body: requestBody,
    }).catch(e => {
      console.error(e);
    });
  };

  const debounceUpdateUserInfo = useCallback(
    _.debounce(updateUserInfo, 300),
    []
  );

  const validateEmail = async (email: string) => {
    // custom basic email validation
    const validEmail =
      email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
    setIsEmailValid(validEmail);
    setEmailErrorText(
      !validEmail ? 'Molimo upišite ispravnu email adresu' : ''
    );
  };

  const handleInfoChange = (elementId: string) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (elementId === EMAIL_INFO_ID) {
      setUserEmail(value);
      validateEmail(value);
    } else if (elementId === PHONE_INFO_ID) {
      setUserPhone(value);
    }
  };

  return (
    <>
      <form>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <Box mr={3}>
              <TextField
                fullWidth
                required
                error={!isEmailValid}
                value={userEmail ? userEmail : ''}
                label="Email"
                id={EMAIL_INFO_ID}
                type="email"
                variant="outlined"
                onChange={handleInfoChange(EMAIL_INFO_ID)}
                helperText={emailErrorText}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box ml={3}>
              <TextField
                fullWidth
                value={userPhone ? userPhone : ''}
                label="Telefon"
                id={PHONE_INFO_ID}
                type="text"
                variant="outlined"
                onChange={handleInfoChange(PHONE_INFO_ID)}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
      <Box mt={3} mb={3}>
        <Typography>
          Informacije se koriste u skladu s privolom za korištenje osobnih
          podataka
        </Typography>
      </Box>
    </>
  );
};

export default UserInfo;
