import { UserDocument } from 'onboarding/redux/types';

import { ChipData } from './types';

export const transformUserDocumentToChipData = (
  documents: UserDocument[]
): ChipData[] => {
  return documents.map(d => ({
    id: d.typeId,
    name: d.name,
    description: d.description,
    isActive: d.uploaded,
    required: d.mandatory,
  }));
};

export const transformPartnerDocumentToChipData = (
  documents: UserDocument[]
): ChipData[] => {
  return documents.map(d => ({
    id: d.typeId,
    name: d.name,
    description: d.description,
    isActive: d.partnerUploaded,
    required: d.partnerMandatory,
  }));
};

export const checkIfAllDocsAreUploaded = (
  userDocs: UserDocument[],
  partnerDocs: UserDocument[]
): boolean => {
  return (
    userDocs.every((d: UserDocument) => !d.mandatory || d.uploaded) &&
    partnerDocs.every((d: UserDocument) => !d.partnerUploaded || d.uploaded)
  );
};
