import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Modal, Paper, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { getForgetUserDataApiRequest } from 'api';

import { useModalStyles } from './styled';
import { AppForgetCmp } from './types';

const AppForget: AppForgetCmp = props => {
  const classes = useModalStyles(props);

  const forgetApiRequest = useSelector(getForgetUserDataApiRequest);

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    try {
      await forgetApiRequest();
      setOpen(false);
      window.location.assign('/');
    } catch (e) {
      window.location.assign('/');
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<DeleteIcon />}
        onClick={handleOpen}
      >
        Zaboravi me
      </Button>
      <Modal
        aria-labelledby="forget-me-modal-title"
        aria-describedby="forget-me-modal-description"
        open={open}
        onClose={handleClose}
      >
        <Paper
          elevation={3}
          style={{
            top: '50%',
            left: '50%',
            transform: `translate(-50%, -50%)`,
          }}
          className={classes.paper}
        >
          <Typography
            variant="h5"
            id="forget-me-modal-title"
            style={{ marginBottom: '2rem' }}
          >
            Zaboravi me
          </Typography>
          <Typography
            paragraph
            id="forget-me-modal-description"
            style={{ marginBottom: '2rem' }}
          >
            Prihvaćanjem ćete obrisati sve podatke koje ste naveli u koracima.
          </Typography>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleConfirm}
            >
              Prihvati
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleClose}
            >
              Odustani
            </Button>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default AppForget;
