import { State } from 'app';
import { getLoans, LoanDocument } from 'formData';
import { getUser, UploadedDocument } from 'user';
import { UserDocument } from './types';
import _ from 'lodash';

export const getLoanDocuments = (state: State): UserDocument[] => {
  const user = getUser(state);
  const loans = getLoans(state);
  const selectedLoanId = user.selectedLoanId;
  const userId = user.id;
  const spouseId = !!user.spouse ? Number(user.spouse.pid) : null;
  const selectedLoan = loans.find(l => l.id === selectedLoanId);

  const uploadedDocuments = getUser(state).documents;

  if (!selectedLoan || !userId) {
    return [];
  }

  return selectedLoan.documents.map(loanDocument => {
    const uploadedDocumentsMatch = getUploadedDocumentMatch(
      loanDocument,
      uploadedDocuments
    );
    return {
      ...loanDocument,
      uploaded: isUploadedByPerson(userId, uploadedDocumentsMatch),
      partnerUploaded: !!spouseId
        ? isUploadedByPerson(spouseId, uploadedDocumentsMatch)
        : false,
    };
  });
};

export const getPartnerLoanDocuments = (state: State): UserDocument[] => {
  const loanDocuments = getLoanDocuments(state);
  return loanDocuments.filter(d => d.partnerMandatory === true);
};

const getUploadedDocumentMatch = (
  loanDocument: LoanDocument,
  uploadedDocuments: UploadedDocument[]
): UploadedDocument[] | undefined => {
  return _.filter(
    uploadedDocuments,
    uploadedDocument => uploadedDocument.typeId === loanDocument.typeId
  );
};

export const getCurrentOnboardingStepId = (state: State): number =>
  state.onboarding.currentStepId;

const checkIsDocumentOwnedByUser = (
  uploadedDoc: UploadedDocument,
  userId: number
): boolean => {
  return _.isEqual(Number(uploadedDoc.personId), Number(userId));
};

const isUploadedByPerson = (
  personId: number,
  uploadedDocuments?: UploadedDocument[]
) => {
  if (!uploadedDocuments) {
    return false;
  }
  return !!_.find(uploadedDocuments, uploadedDocument =>
    checkIsDocumentOwnedByUser(uploadedDocument, personId)
  );
};

// Show spouse document after marriage certificate is uploaded
// try better implementation with backend
export const getShowPartnerDocuments = (state: State): boolean => {
  const user = getUser(state);
  const uploadedDocuments = getUser(state).documents;
  const spouseId = !!user.spouse ? Number(user.spouse.pid) : null;
  const userId = user.id;

  // marriage certificate is document typeId = 2 and should users certificate or partners
  return !!_.find(uploadedDocuments, uploadedDoc => {
    const uploadedByUser = userId ? checkIsDocumentOwnedByUser(uploadedDoc, userId) : false;
    const uploadedByPartner = spouseId ? checkIsDocumentOwnedByUser(uploadedDoc, spouseId) : false;
    return (
      uploadedDoc.typeId === 2 && (uploadedByUser || uploadedByPartner)
    );
  });
};
