import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ROUTES, ONBOARDING_ROUTE_INDEX } from 'routes';
import { getLoans, getFromDataLoading, getFromDataEmpty } from 'formData';
import { getUser, fetchUser } from 'user';
import { ApiMethod, getUserApiRequest } from 'api';

import { OnboardingPageCmp } from '../../types';
import { OnboardingPageContainer, CreditCard } from '../../components';
import { CardsContainer } from '../components';

const CreditTypePage: OnboardingPageCmp = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const loans = useSelector(getLoans);
  const userApiRequest = useSelector(getUserApiRequest);

  const [activeCreditId, setActiveCredit] = useState<number | null>(user.selectedLoanId);
  const isFromDataLoading = useSelector(getFromDataLoading);
  const isFromDataEmpty = useSelector(getFromDataEmpty);

  const handleCardClick = (id: number) => {
    setActiveCredit(id);
    updateSelectedLoan(id).then(() => dispatch(fetchUser()));
  };

  const updateSelectedLoan = (id?: number) => {
    return userApiRequest({
      method: ApiMethod.PUT,
      body: {
        selected_loan_id: id ? id : activeCreditId,
        last_onboarding_step_id: ONBOARDING_ROUTE_INDEX.creditType,
      },
    });
  };

  const renderRealData = () => {
    return loans.map((loan, i) => (
      <CreditCard
        name={loan.type}
        // TODO: Add correct icons once that info is available from server
        icon="random"
        description={loan.description}
        active={activeCreditId === loan.id}
        key={`creditcard${i}`}
        onClick={handleCardClick}
        id={loan.id}
      />
    ));
  };

  const renderSkeletons = () => {
    return [...new Array(8)].map((item, i) => (
      <CreditCard.Skeleton key={`skeletonCard${i}`} />
    ));
  };

  const shouldLoadSkeleton = isFromDataLoading || isFromDataEmpty;

  return (
    <OnboardingPageContainer
      title="Odaberite željenu vrstu kredita"
      buttonHoverMsg="Molimo odaberite željeni kredit prije nastavka"
      nextButtonActive={!!activeCreditId || activeCreditId === 0}
      // onNextPress={handleNextPress}
    >
      <CardsContainer>
        {shouldLoadSkeleton ? renderSkeletons() : renderRealData()}
      </CardsContainer>
    </OnboardingPageContainer>
  );
};

CreditTypePage.route = ROUTES.creditType;

export default CreditTypePage;
