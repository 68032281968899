import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Skeleton } from '@material-ui/lab';
import { ChipsCmp, ChipsProps } from './types';
import { useChipStyles } from './styled';
import { MoreInfoDialog } from '../MoreInfoDialog';
import { CHIP_SKELETON_HEIGHT, CHIP_SKELETON_WIDTH } from './const';

export const Chips: ChipsCmp = (props: ChipsProps) => {
  const { data } = props;

  const [visibleDialogId, setVisibleDialogId] = useState<number | null>(null);

  const handleDialogHide = () => {
    setVisibleDialogId(null);
  };

  const handleDialogShow = (id: number) => {
    setVisibleDialogId(id);
  };

  const classes = useChipStyles();
  return (
    <div className={classes.root}>
      {data.map(c => (
        <div key={c.id}>
          <Chip
            color={c.isActive ? 'primary' : 'default'}
            variant={c.isActive ? 'default' : 'outlined'}
            key={c.id}
            label={`${c.name}${c.required ? '*' : ''}`}
            icon={<InfoIcon />}
            clickable
            onClick={() => handleDialogShow(c.id)}
          />
          <MoreInfoDialog
            title={c.name}
            text={c.description}
            open={visibleDialogId === c.id}
            onClose={handleDialogHide}
          />
        </div>
      ))}
    </div>
  );
};

const ChipSkeleton = () => {
  return <Skeleton height={CHIP_SKELETON_HEIGHT} width={CHIP_SKELETON_WIDTH} />;
};

Chips.Skeleton = ChipSkeleton;

export default Chips;
