import { User } from 'user';
import { GeneratedDocument } from 'generatedDocuments';

const SIGNED_STATUS = 'signed';

export const isDocumentSigned = (document: GeneratedDocument) => {
  return document.status === SIGNED_STATUS;
}

export const extractDocumentContent = (document: any) => {
  return document.file_content;
};

export const generateDocumentName = (name?: string, user?: User) => {
  let documentName = '';
  if (name) {
    documentName += name;
  } else {
    documentName = 'Reputator';
  }
  if (user) {
    documentName = `${documentName}${documentName !== '' ? '_' : ''}${
      user.firstName
    }_${user.lastName}`;
  }

  return documentName;
};
