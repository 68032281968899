import React from 'react';

import { Icon } from 'components';
import { IconNames } from 'theme';

import { AppTitleCmp } from './types';
import { useTitleStyle } from './styled';

const AppTitle: AppTitleCmp = () => {
  const classes = useTitleStyle();

  return <Icon name={IconNames.LogoWithText} className={classes.title} />;
};

export default AppTitle;
