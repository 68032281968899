import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import _ from 'lodash';

import { getUserApiRequest, ApiMethod } from 'api';
import { getRequestedCredit } from 'user/redux/selectors';
import { ROUTES } from 'routes';

import {
  getCreditOptions,
  getCreditOptionsEmpty,
  getCreditOptionsLoading,
  fetchCreditOptions,
} from 'credit-options';
import { OnboardingPageContainer, CreditPreview } from '../../components';
import { CreditSlider } from '../components';
import { OnboardingPageCmp } from '../../types';
import { calculateMonthlyPaymentAmount, getPeriodUnitLabel } from '../../utils';
import { AMOUNT_SLIDER_ID, DURATION_SLIDER_ID } from './const';

const CreditOptionsPage: OnboardingPageCmp = () => {
  const dispatch = useDispatch();
  const creditOptions = useSelector(getCreditOptions);
  const userApiRequest = useSelector(getUserApiRequest);
  const isCreditOptionsEmpty = useSelector(getCreditOptionsEmpty);
  const isCreditOptionsLoading = useSelector(getCreditOptionsLoading);
  const requestedCredit = useSelector(getRequestedCredit);

  const [creditAmount, setCreditAmount] = useState<number>(10000);
  const [creditDuration, setCreditDuration] = useState<number>(1);
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    dispatch(fetchCreditOptions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestedCredit) {
      if (_.isNumber(requestedCredit.totalAmount)) {
        setCreditAmount(requestedCredit.totalAmount);
      }
      if (_.isNumber(requestedCredit.repaymentMonths)) {
        setCreditDuration(requestedCredit.repaymentMonths);
      }
    }
  }, [requestedCredit]);

  const handleChange = (elementId: string, newValue: number) => {
    if (elementId === AMOUNT_SLIDER_ID) {
      setCreditAmount(newValue);
    } else if (elementId === DURATION_SLIDER_ID) {
      setCreditDuration(newValue);
    }
  };

  const handleNextPress = async (): Promise<boolean> => {
    const body = {
      req_credit_currency: 'HRK',
      req_credit_monthly_rate: calculateMonthlyPaymentAmount(
        creditAmount!,
        creditOptions.estimatedInterestRate!,
        creditDuration!
      ),
      req_credit_name: 'Postojeći kredit',
      req_credit_repayment_months: creditDuration,
      req_credit_total: creditAmount,
    };
    try {
      await userApiRequest({
        method: ApiMethod.PUT,
        body,
      });
      return true;
    } catch (e) {
      return false;
    }
  };

  const renderRealData = () => {
    const maxAmount =
      creditOptions.maxAmount !== null
        ? Math.round(creditOptions.maxAmount)
        : 1000000;
    const maxDuration =
      creditOptions.maxPeriod !== null ? creditOptions.maxPeriod : 30;

    const periodUnit = getPeriodUnitLabel(
      creditOptions.periodUnit!,
      creditDuration
    );

    return (
      <>
        <CreditSlider
          sliderId={AMOUNT_SLIDER_ID}
          title="Iznos kredita"
          value={creditAmount}
          unit={creditOptions.currency}
          onSliderChange={handleChange}
          min={10000}
          max={maxAmount}
          step={1000}
        />
        <CreditSlider
          sliderId={DURATION_SLIDER_ID}
          title="Duljina otplate kredita"
          value={creditDuration}
          unit={periodUnit}
          onSliderChange={handleChange}
          min={1}
          max={maxDuration}
        />
        <CreditPreview
          amount={creditAmount!}
          numberOfPayments={creditDuration!}
          interestRate={creditOptions!.estimatedInterestRate!}
          currency={creditOptions!.currency!}
          durationUnit={periodUnit}
          maxMonthlyPayment={creditOptions.maxRate || undefined}
          onValidation={setIsValid}
        />
      </>
    );
  };

  const renderSkeletons = () => {
    return (
      <>
        <CreditSlider.Skeleton />
        <CreditSlider.Skeleton />
        <CreditPreview.Skeleton />
      </>
    );
  };

  const shouldLoadSkeleton = isCreditOptionsEmpty || isCreditOptionsLoading;

  return (
    <OnboardingPageContainer
      title="Iznos željenog kredita"
      buttonHoverMsg="Molimo odaberite željeni kredit u skladu s Vašim mogućnostima"
      nextButtonActive={isValid}
      onNextPress={handleNextPress}
    >
      <Box mb={3}>
        <Typography>
          Iz priloženih dokumenata Reputator je izračunao maksimalne vrijednosti
          kredita koji možete dobiti. Odaberite željene opcije kredita.
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h5">Podaci o kreditu</Typography>
      </Box>

      {shouldLoadSkeleton ? renderSkeletons() : renderRealData()}
    </OnboardingPageContainer>
  );
};

CreditOptionsPage.route = ROUTES.creditOptions;

export default CreditOptionsPage;
