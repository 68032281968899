export enum CreditCurrency {
  HRK = 'HRK',
  EUR = 'EURO',
}

export enum CreditPeriodUnit {
  YEAR = 'year',
  MONTH = 'month',
}

export interface CreditOptions {
  currency: null | CreditCurrency;
  estimatedInterestRate: null | number;
  maxAmount: null | number;
  maxPeriod: null | number;
  maxRate: null | number;
  periodUnit: null | CreditPeriodUnit;
  personalId: null | string;
}
