import styled from 'styled-components';

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px;

  & > button {
    margin: 12px;
  }

  & > div.MuiSkeleton-root {
    margin: 12px;
  }
`;
