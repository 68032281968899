import { APP_MIN_WIDTH, APP_MAX_WIDTH } from './const';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const useAppHomeStyles = makeStyles((theme: Theme) => ({
  homeWrapper: {
    display: 'flex',
    'min-width': APP_MIN_WIDTH,
    'max-width': APP_MAX_WIDTH,
    height: '100%',
    position: 'relative',
    'padding-top': 100,
    'padding-bottom': 100,
    'margin-left': 'auto',
    'margin-right': 'auto',
  },
  smallBottomPadding: {
    'padding-bottom': theme.spacing() * 1,
  },
  largeBottomPadding: {
    'padding-bottom': theme.spacing() * 4,
  },
  contentMargin: {
    'margin-bottom': theme.spacing() * 3,
    'padding': theme.spacing() * 4,
    'margin-left': 2,
    'margin-right': 2,
  },
}));
