import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { Grid, Typography, Tooltip, IconButton, Box } from '@material-ui/core';

import { UploadedDocCmp, UploadedDocProps } from './types';

export const UploadedDoc: UploadedDocCmp = (props: UploadedDocProps) => {
  const { name, onDelete, index } = props;

  const handleDelete = () => {
    onDelete(index);
  };

  const shortenName =
    name.length > 55
      ? name.substring(0, 50) + '...' + name.substring(name.length - 4)
      : name;

  return (
    <Grid container justify="space-between">
      <Grid item>
        <Grid container>
          <Grid item>
            <Box pr={2}>
              <FileCopyIcon />
            </Box>
          </Grid>
          <Grid item>
            <Tooltip title={name}>
              <Typography variant="overline">{shortenName}</Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <IconButton size="small" aria-label="delete" onClick={handleDelete}>
          <ClearIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
