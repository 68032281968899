import { createApiAction } from 'api';
import { StartAction, SuccessAction, FailureAction } from 'api/redux/types'; // todo fix this importing

export enum UserActionTypes {
  FETCH_CREDIT_OPTIONS_START = 'credit_options/fetch_start',
  FETCH_CREDIT_OPTIONS_SUCCESS = 'credit_options/fetch_success',
  FETCH_CREDIT_OPTIONS_FAILURE = 'credit_options/fetch_failure',
}

const getCreditOptionsStart = (): StartAction => ({
  type: UserActionTypes.FETCH_CREDIT_OPTIONS_START,
  payload: null,
});

const getCreditOptionsSuccess = (payload: any /*TODO*/): SuccessAction => ({
  type: UserActionTypes.FETCH_CREDIT_OPTIONS_SUCCESS,
  payload,
});

const getCreditOptionsFailure = (error: any /*TODO*/): FailureAction => ({
  type: UserActionTypes.FETCH_CREDIT_OPTIONS_FAILURE,
  payload: { error },
});

export const fetchCreditOptions = () =>
  createApiAction(
    {
      path: '/ccr/client/getCreditParams.json',
      method: 'GET',
    },
    getCreditOptionsStart,
    getCreditOptionsSuccess,
    getCreditOptionsFailure
  );
