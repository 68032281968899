import React from 'react';
import { Box, Grid, Typography, Slider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { NumberFormatLabel } from '../../../components';
import { CreditSliderCmp, CreditSliderProps, SkeletonCmp } from './types';
import { SKELETON_HEIGHT, SKELETON_WIDTH } from './const';

const CreditSlider: CreditSliderCmp = (props: CreditSliderProps) => {
  const { sliderId, title, value, unit, onSliderChange, ...other } = props;

  const handleChange = (event: any, newValue: number | number[]) => {
    onSliderChange(sliderId, newValue as number);
  };

  return (
    <Box mb={3}>
      <Grid container spacing={1}>
        <Grid item md={3}>
          <Typography id={sliderId} gutterBottom>
            {title}:
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography style={{ float: 'right', fontWeight: 'bold' }}>
            <NumberFormatLabel value={value} unit={unit!} withDecimal={false} />
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Grid item md={6}>
            <Slider
              {...other}
              value={value}
              id={`${sliderId}-slider`}
              aria-labelledby={sliderId}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const CreditSliderSkeleton: SkeletonCmp = () => {
  return (
    <Box mb={2}>
      <Skeleton height={SKELETON_HEIGHT} width={SKELETON_WIDTH} />
    </Box>
  );
};

CreditSlider.Skeleton = CreditSliderSkeleton;

export default CreditSlider;
