import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';

import { getCreditOptions, CreditPeriodUnit } from 'credit-options';
import { getUser } from 'user';
import { getLoan } from 'formData/redux/selectors';
import { getLoanDocuments, getPartnerLoanDocuments } from 'onboarding/redux';
import {
  getUserEmpty,
  getUserLoading,
  getExistingCredit,
  getRequestedCredit,
} from 'user/redux/selectors';
import { ROUTES } from 'routes';

import {
  OnboardingPageContainer,
  CreditCard,
  Chips,
  CreditPreview,
  NumberFormatLabel,
} from '../../components';
import { OnboardingPageCmp } from '../../types';
import { getPeriodUnitLabel } from '../../utils';
import {
  transformUserDocumentToChipData,
  transformPartnerDocumentToChipData,
} from 'onboarding/components/Chips/utils';

const OverviewPage: OnboardingPageCmp = () => {
  const requiredDocs = [
    {
      id: 0,
      name: 'PSD2 datoteka',
      description: 'PSD2 datoteka treba biti u .json formatu',
      isActive: true,
    },
  ];

  const user = useSelector(getUser);
  const loan = useSelector(getLoan(user.selectedLoanId!));
  const existingCredit = useSelector(getExistingCredit);
  const requestedCredit = useSelector(getRequestedCredit);
  const loanDocuments = useSelector(getLoanDocuments);
  const userDocuments = transformUserDocumentToChipData(loanDocuments);
  const partnerLoanDocuments = useSelector(getPartnerLoanDocuments);
  const partnerDocuments = transformPartnerDocumentToChipData(
    partnerLoanDocuments
  );
  const isUserEmpty = useSelector(getUserEmpty);
  const isUserLoading = useSelector(getUserLoading);
  const { estimatedInterestRate } = useSelector(getCreditOptions);

  const renderRealData = () => {
    const periodUnit = getPeriodUnitLabel(
      CreditPeriodUnit.MONTH,
      requestedCredit!.repaymentMonths || undefined
    );

    return (
      <>
        <Box mb={3}>
          <Box mb={3}>
            <Typography variant="h5">Odabrana vrsta kredita</Typography>
          </Box>
          {!!loan && (
            <CreditCard
              name={loan.type}
              icon="random"
              description={loan.group}
              active={true}
              id={loan.id}
            />
          )}
        </Box>

        <Box mb={3}>
          <Box mb={3}>
            <Typography variant="h5">Priloženi dokumenti</Typography>
          </Box>
          <Grid item xs={8}>
            <Chips data={userDocuments} />
          </Grid>
          {user.isMarried && partnerDocuments.length ? (
            <>
              <Grid item>
                <Box m={2}>
                  <Typography variant="body1">Dokumenti partnera</Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Chips data={partnerDocuments} />
              </Grid>
            </>
          ) : null}
        </Box>

        <Box mb={3}>
          <Box mb={3}>
            <Typography variant="h5">Bankovni podaci</Typography>
          </Box>
          <Grid item xs={8}>
            <Chips data={requiredDocs} />
          </Grid>
        </Box>

        <Box mb={3}>
          <Box mb={3}>
            <Typography variant="h5">
              Vaše trenutno kreditno opterećenje
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item md={2}>
              <Box fontWeight="fontWeightBold">Ukupan iznos kredita:</Box>
            </Grid>
            <Grid item md={5}>
              <Box>
                <NumberFormatLabel
                  value={existingCredit!.totalAmount || 0}
                  unit={existingCredit!.currency || 'HRK'}
                  withDecimal
                />
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box fontWeight="fontWeightBold">Mjesečni iznos:</Box>
            </Grid>
            <Grid item md={3}>
              <Box>
                <NumberFormatLabel
                  value={existingCredit!.monthlyRate || 0}
                  unit={existingCredit!.currency || 'HRK'}
                  withDecimal
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mb={3}>
          <Typography variant="h5">Odabrani iznos željenog kredita</Typography>
        </Box>
        <CreditPreview
          amount={requestedCredit!.totalAmount || 0}
          numberOfPayments={requestedCredit!.repaymentMonths || 0}
          interestRate={estimatedInterestRate || 0}
          currency={requestedCredit!.currency || 'HRK'}
          durationUnit={periodUnit}
          onValidation={_.noop}
        />
      </>
    );
  };

  const renderSkeletons = () => {
    return (
      <>
        <Box mb={3}>
          <Box mb={3}>
            <Typography variant="h5">Odabrana vrsta kredita</Typography>
          </Box>
          <CreditCard.Skeleton />
        </Box>

        <Box mb={3}>
          <Box mb={3}>
            <Typography variant="h5">Priloženi dokumenti</Typography>
          </Box>
          <Grid item xs={8}>
            <Chips.Skeleton />
          </Grid>
        </Box>

        <Box mb={3}>
          <Box mb={3}>
            <Typography variant="h5">Bankovni podaci</Typography>
          </Box>
          <Grid item xs={8}>
            <Chips.Skeleton />
          </Grid>
        </Box>

        <Box mb={3}>
          <Box mb={3}>
            <Typography variant="h5">
              Vaše trenutno kreditno opterećenje
            </Typography>
          </Box>

          <Grid container spacing={1}>
            <Skeleton width={'80%'} height={30} />
          </Grid>
        </Box>

        <Box mb={3}>
          <Typography variant="h5">Odabrani iznos željenog kredita</Typography>
        </Box>
        <CreditPreview.Skeleton />
      </>
    );
  };

  const shouldLoadSkeleton = isUserEmpty || isUserLoading;

  return (
    <OnboardingPageContainer
      title="Pregled unesenih podataka i željenog kredita"
      nextButtonActive
    >
      {shouldLoadSkeleton ? renderSkeletons() : renderRealData()}
    </OnboardingPageContainer>
  );
};

OverviewPage.route = ROUTES.overview;

export default OverviewPage;
