import _ from 'lodash';

import { State } from 'app';

import {
  ApiConfig,
  UserApiConfig,
  ApiMethod,
  FileUploadApiConfig,
  GenerateReportApiConfig,
} from './types';

// TODO state is left here for future auth needs
export const createApiRequest = (state: State) => async (
  apiConfig: ApiConfig
) => {
  const { path, method, body, headers } = apiConfig;

  let init: RequestInit = {
    method,
    body,
  };

  if (headers) {
    init = { ...init, headers };
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/ccr/${path || ''}`,
      init
    );

    if (response.ok) {
      return await response.json();
    }

    throw new Error(`API error: '${response.status}', ${response}`);
  } catch (e) {
    throw new Error(`API error: ${e.message}`);
  }
};

export const createUserApiRequest = (state: State) => async (
  userApiConfig: UserApiConfig
) => {
  const { method, body } = userApiConfig;

  const path = 'fe/userData.json';

  const contentType = _.includes(['POST', 'PUT', 'PATCH'], method)
    ? { 'Content-Type': 'application/json' }
    : null;
  const headers = {
    ...contentType,
  };

  return createApiRequest(state)({
    method,
    path,
    body: JSON.stringify(body),
    headers,
  });
};

export const createFileUploadApiRequest = (state: State) => async (
  userApiConfig: FileUploadApiConfig
) => {
  const { files, path } = userApiConfig;
  const formData = new FormData();
  _.forEach(files, (f: File) => {
    formData.append('files', f);
  });

  return createApiRequest(state)({
    method: ApiMethod.POST,
    path,
    body: formData,
  });
};

export const createGenerateReportApiRequest = (state: State) => async (
  generateReportApiConfig: GenerateReportApiConfig
) => {
  const { body } = generateReportApiConfig;

  const path = 'report/runReport.json';

  const headers = {
    ...{ 'Content-Type': 'application/json' },
  };

  return createApiRequest(state)({
    method: ApiMethod.POST,
    path,
    body: JSON.stringify(body),
    headers,
  });
};

export const createHwcryptoCreateContainerApiRequest = (state: State) => async (
  signatureInHex: any
) => {
  const path = 'hwcrypto/DSS/createContainer.json';

  const headers = {
    ...{ 'Content-Type': 'application/json' },
  };

  return createApiRequest(state)({
    method: ApiMethod.POST,
    path,
    body: JSON.stringify({ signatureInHex }),
    headers,
  });
};

export const createHwcryptoGenerateHashApiRequest = (state: State) => async (
  certInHex: string,
  documentId?: number
) => {
  let path = 'hwcrypto/DSS/generateHash.json';
  if (documentId) {
    path += `/${documentId}`;
  }

  const headers = {
    ...{ 'Content-Type': 'application/json' },
  };

  return createApiRequest(state)({
    method: ApiMethod.POST,
    path,
    body: JSON.stringify({ certInHex }),
    headers,
  });
};

export const createDownloadDocumentApiRequest = (state: State) => async (
  documentId: number
) => {
  const path = `client/getGeneratedDocument/${documentId}`;

  return createApiRequest(state)({
    method: ApiMethod.GET,
    path,
  });
};

export const createForgetUserDataApiRequest = (state: State) => async () => {
  const path = 'client/deleteAllData.json';

  return createApiRequest(state)({
    method: ApiMethod.DELETE,
    path,
  });
};
