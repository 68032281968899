import { LoanDocument } from 'formData';

export interface UserDocument extends LoanDocument {
  uploaded: boolean;
  partnerUploaded: boolean;
}

export const SET_CURRENT_ONBOARDING_STEP_ID =
  'onboarding/set_current_onboarding_step_id';
export const INCREASE_CURRENT_ONBOARDING_STEP_ID =
  'onboarding/increase_current_onboarding_step_id';

export interface CurrentOnboardingStepId {
  currentStepId: number;
}

export interface SetCurrentOnboardingStepIdAction {
  type: typeof SET_CURRENT_ONBOARDING_STEP_ID;
  payload: CurrentOnboardingStepId;
}

export interface IncreaseCurrentOnboardingStepIdAction {
  type: typeof INCREASE_CURRENT_ONBOARDING_STEP_ID;
}

export type OnboardingActionTypes =
  | SetCurrentOnboardingStepIdAction
  | IncreaseCurrentOnboardingStepIdAction;

export interface OnboardingState {
  currentStepId: number;
}
