import { icons } from 'theme';
import React from 'react';

import { IconCmp, IconProps } from './types';

const Icon: IconCmp = (props: IconProps) => {
  const { name, className } = props;
  const ResolvedIcon = icons[name];
  return <ResolvedIcon className={className} />;
};

export default Icon;
