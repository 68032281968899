import React from 'react';
import _ from 'lodash';
import { Box } from '@material-ui/core';

import InfoItem from './InfoItem';
import { CompanyInfoCmp, CompanyInfoProps } from './types';
import { basicInfoKeysWithLabels, currentInfoKeysWithLabels } from './consts';

const CompanyInfo: CompanyInfoCmp = ({
  isLoading,
  companyDescription,
  info,
}: CompanyInfoProps) => {
  const keysWithLabelsToShow =
    info === 'basic' ? basicInfoKeysWithLabels : currentInfoKeysWithLabels;
  const valuesToShow = _.pick(companyDescription, _.keys(keysWithLabelsToShow));

  const companyDescriptionPairs = _.toPairs(valuesToShow);

  const renderPairedInfo = (
    firstItem: [string, null | string | boolean | number | undefined],
    secondItem: [string, null | string | boolean | number | undefined],
    index: number
  ) => (
    <Box
      display="flex"
      justifyContent="space-between"
      mb={0.5}
      key={`${firstItem}${index}`}
    >
      <InfoItem
        title={`${keysWithLabelsToShow[firstItem[0]]}:`}
        value={firstItem[1]}
        isLoading={isLoading}
      />
      {secondItem && (
        <InfoItem
          title={`${keysWithLabelsToShow[secondItem[0]]}:`}
          value={secondItem[1]}
          isLoading={isLoading}
        />
      )}
    </Box>
  );

  return (
    <>
      {companyDescriptionPairs.map((pair, index) => {
        const isIndexEven = index % 2 === 0;
        if (isIndexEven) {
          const nextPair = companyDescriptionPairs[index + 1];
          return renderPairedInfo(pair, nextPair, index);
        }
        return null;
      })}
    </>
  );
};

export default CompanyInfo;
