import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box } from '@material-ui/core';

import { ROUTES } from 'routes';
import { getIsUserMarried, getUserFullName, getSpouseFullName } from 'user';
import { getFromDataLoading, getFromDataEmpty } from 'formData';
import {
  fetchCreditWorthiness,
  getCreditworthinessEmpty,
  getCreditworthinessLoading,
  getCreditworthiness,
} from 'creditworthiness';

import { TabSelector, CompanyInfo, CompanyChart } from '../components';
import { OnboardingPageCmp } from '../../types';
import { OnboardingPageContainer } from '../../components';

const EmployerPage: OnboardingPageCmp = () => {
  const [activeTabIndex, setActiveTaxIndex] = useState(0);
  const isUserMarried = useSelector(getIsUserMarried);
  const userFullName = useSelector(getUserFullName);
  const spouseFullName = useSelector(getSpouseFullName);
  const creditworthiness = useSelector(getCreditworthiness);
  const isFromDataLoading = useSelector(getFromDataLoading);
  const isFromDataEmpty = useSelector(getFromDataEmpty);
  const isCreditworthinessEmpty = useSelector(getCreditworthinessEmpty);
  const isCreditworthinessLoading = useSelector(getCreditworthinessLoading);

  const shouldLoadSkeleton =
    isFromDataLoading ||
    isFromDataEmpty ||
    isCreditworthinessEmpty ||
    isCreditworthinessLoading;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCreditWorthiness());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) =>
    setActiveTaxIndex(newValue);

  const companyData =
    activeTabIndex === 0 ? creditworthiness.client : creditworthiness.spouse;

  return (
    <OnboardingPageContainer title="Pregled Vašeg poslodavca" nextButtonActive>
      <Box pb={2}>
        <Typography>
          Iz priloženih dokumenata i javno dostupnih informacija Reputator je
          uspio dobiti sljedeće informacije o bonitetu poslodavca za kojeg
          radite.
        </Typography>
      </Box>

      {(isUserMarried || shouldLoadSkeleton) && (
        <Box pb={4}>
          <TabSelector
            activeIndex={activeTabIndex}
            onChange={handleTabChange}
            userName={userFullName}
            spouseName={spouseFullName || ''}
            isLoading={shouldLoadSkeleton}
          />
        </Box>
      )}

      <Box pb={3}>
        <Typography variant="h5">Opći podaci</Typography>
      </Box>
      <Box pb={4}>
        <CompanyInfo
          info="basic"
          isLoading={shouldLoadSkeleton}
          companyDescription={companyData.companyDescription}
        />
      </Box>

      <Box pb={3}>
        <Typography variant="h5">Aktualne informacije</Typography>
      </Box>
      <Box pb={4}>
        <CompanyInfo
          info="current"
          isLoading={shouldLoadSkeleton}
          companyDescription={companyData.companyDescription}
        />
      </Box>

      <CompanyChart data={companyData.companyAssetsCapital} />
    </OnboardingPageContainer>
  );
};

EmployerPage.route = ROUTES.employer;

export default EmployerPage;
