const CHECKBOX_SKELETON_WIDTH = 500;

const CHECKBOX_SKELETON_HEIGHT = 24;

export { CHECKBOX_SKELETON_WIDTH, CHECKBOX_SKELETON_HEIGHT }

export const consentTypes = [
  {
    id: 1,
    name: 'cookie',
    description: 'Korisnik se slaže sa korištenjem kolačića',
    required: true,
    bodyName: 'consent_cookie',
  },
  {
    id: 2,
    name: 'gdpr',
    description: 'Korisnik se slaže sa uporabom osobnih podataka',
    required: true,
    bodyName: 'consent_gdpr',
  },
  {
    id: 3,
    name: 'disclaimer',
    description: 'Pružatelj usluge nije za ništa odgovoran',
    required: true,
    bodyName: 'consent_disclamer',
  },
  {
    id: 4,
    name: 'marketing',
    description:
      'Korisnik se slaže sa primanjem promotivnih materijala i obavijesti',
    required: false,
    bodyName: 'consent_marketing',
  },
];
