import { createApiAction } from 'api';
import { StartAction, SuccessAction, FailureAction } from 'api/redux/types'; // todo fix this importing

export enum UserActionTypes {
  FETCH_USER_START = 'user/fetch_start',
  FETCH_USER_SUCCESS = 'user/fetch_success',
  FETCH_USER_FAILURE = 'user/fetch_failure',
}

const fetchUserStart = (): StartAction => ({
  type: UserActionTypes.FETCH_USER_START,
  payload: null,
});

const fetchUserSuccess = (payload: any /*TODO*/): SuccessAction => ({
  type: UserActionTypes.FETCH_USER_SUCCESS,
  payload,
});

const fetchUserFailure = (error: any /*TODO*/): FailureAction => ({
  type: UserActionTypes.FETCH_USER_FAILURE,
  payload: { error },
});

export const fetchUser = (/*TODO*/) =>
  createApiAction(
    {
      path: '/ccr/fe/user.json',
      method: 'GET',
    },
    fetchUserStart,
    fetchUserSuccess,
    fetchUserFailure
  );
