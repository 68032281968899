import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Button, Divider, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';

import { DocUploaderCmp, DocUploaderProps } from './types';
import { UploadedDoc } from './UploadedDoc';
import { useDocStyles } from './styled';

export const DocUploader: DocUploaderCmp = (props: DocUploaderProps) => {
  const classes = useDocStyles();

  const [droppedDocs, setDroppedDocs] = useState<Dropzone.DropzoneFile[]>([]);

  const onDrop = useCallback(
    acceptedFiles => {
      setDroppedDocs([...droppedDocs, ...acceptedFiles]);
    },
    [droppedDocs, setDroppedDocs]
  );

  const handleDelete = (index: number) => {
    setDroppedDocs([
      ...droppedDocs.slice(0, index),
      ...droppedDocs.slice(index + 1, droppedDocs.length),
    ]);
  };

  const { onUploadPress } = props;

  const handleUploadPress = async () => {
    const success = await onUploadPress(droppedDocs);

    if (success) {
      setDroppedDocs([]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const renderPopulatedUploader = () => (
    <Grid
      container
      direction="column"
      spacing={1}
      justify="space-between"
      className={classes.container}
    >
      {droppedDocs.map((d, i) => (
        <Grid key={i} item xs={12}>
          <UploadedDoc name={d.name} index={i} onDelete={handleDelete} />
        </Grid>
      ))}
      <Grid item>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button startIcon={<AddIcon />}>DODAJ NOVI DOKUMENT</Button>
        </div>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item container direction="row" justify="center" spacing={1}>
        <Grid item>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={handleUploadPress}
          >
            UČITAJ PREDANE DOKUMENTE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderEmptyUploader = () => (
    <div {...getRootProps()} className={classes.container}>
      <input {...getInputProps()} />
      <Grid
        container
        spacing={2}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Typography>
            {isDragActive
              ? 'Odvucite dokumente ovdje'
              : 'Odvucite dokumente ovdje ili klikni za početak učitavanja'}
          </Typography>
        </Grid>
        <Grid item>
          <CloudUploadOutlinedIcon style={{ fontSize: 33 }} />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div className={classes.root}>
      {!droppedDocs.length || isDragActive
        ? renderEmptyUploader()
        : renderPopulatedUploader()}
    </div>
  );
};
