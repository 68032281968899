const OVERALL_DEBT_ID = 'overall-debt';
const MONTHLY_DEBT_ID = 'monthly-debt';
const CONSENT_CHECK_ID = 'consent-check';
const PAYMENTS_CHECK_ID = 'payment-regularity-check';

export {
  MONTHLY_DEBT_ID,
  OVERALL_DEBT_ID,
  CONSENT_CHECK_ID,
  PAYMENTS_CHECK_ID,
};
