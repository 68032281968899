import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const usePageContainerStyles = makeStyles((theme: Theme) => ({
  smallBottomPadding: {
    'padding-bottom': theme.spacing() * 1,
  },
  largeBottomPadding: {
    'padding-bottom': theme.spacing() * 4,
  },
  contentMargin: {
    'margin-bottom': theme.spacing() * 3,
    'padding': theme.spacing() * 4,
    'margin-left': 2,
    'margin-right': 2,
  },
}));
