import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { TabSelectorCmp, TabSelectorProps } from './types';

const TabSelector: TabSelectorCmp = ({
  activeIndex,
  onChange,
  userName,
  spouseName,
  isLoading,
}: TabSelectorProps) => {
  if (isLoading) {
    return <Skeleton width="100%" height={48} style={{ margin: 0 }} />;
  }
  return (
    <Tabs
      value={activeIndex}
      onChange={onChange}
      textColor="primary"
      indicatorColor="primary"
      centered
    >
      <Tab label={userName} />
      <Tab label={spouseName} />
    </Tabs>
  );
};

export default TabSelector;
