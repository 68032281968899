import { ReactComponent as Price } from './assets/price.svg';
import { ReactComponent as LogoWithText } from './assets/logoWithText.svg';
import { ReactComponent as CreditCards } from './assets/creditCards.svg';
import { ReactComponent as Diamonds } from './assets/diamonds.svg';
import { ReactComponent as EducationalSaving } from './assets/educationalSaving.svg';
import { ReactComponent as HouseSaving } from './assets/houseSaving.svg';
import { ReactComponent as Leasing } from './assets/leasing.svg';
import { ReactComponent as MoneyBag } from './assets/moneyBag.svg';
import { ReactComponent as Mortgage } from './assets/mortgage.svg';
import { ReactComponent as Wallet } from './assets/wallet.svg';

export enum IconNames {
  Price = 'Price',
  LogoWithText = 'LogoWithText',
  CreditCards = 'CreditCards',
  Diamonds = 'Diamonds',
  EducationalSavings = 'EducationalSavings',
  HouseSaving = 'HouseSaving',
  Leasing = 'Leasing',
  MoneyBag = 'MoneyBag',
  Mortgage = 'Mortgage',
  Wallet = 'Wallet',
}

export const icons = Object.freeze({
  [IconNames.Price]: Price,
  [IconNames.LogoWithText]: LogoWithText,
  [IconNames.CreditCards]: CreditCards,
  [IconNames.Diamonds]: Diamonds,
  [IconNames.EducationalSavings]: EducationalSaving,
  [IconNames.Leasing]: Leasing,
  [IconNames.MoneyBag]: MoneyBag,
  [IconNames.HouseSaving]: HouseSaving,
  [IconNames.Mortgage]: Mortgage,
  [IconNames.Wallet]: Wallet,
});
