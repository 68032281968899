import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
} from '@material-ui/core';

import { MoreInfoDialogProps, MoreInfoDialogCmp } from './types';

const MoreInfoDialog: MoreInfoDialogCmp = (props: MoreInfoDialogProps) => {
  const { title, text, open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default MoreInfoDialog;
