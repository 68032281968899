import _ from 'lodash';
import { createApiReducer, chainReducers, INITIAL_API_STATUS } from 'api';
import { UserActionTypes } from './actions';

import { nameFormat } from '../services';
import { User, UploadedDocument } from '../types';
import { UserState } from './types';

const initialState: UserState = {
  id: null,
  lastOnboardingStepId: 0,
  firstName: '',
  lastName: '',
  selectedLoanId: null,
  isMarried: null,
  spouse: null,
  consents: {
    cookie: null,
    disclaimer: null,
    gdpr: null,
    marketing: null,
  },
  requestedCredit: {
    currency: null,
    monthlyRate: null,
    name: null,
    repaymentMonths: null,
    totalAmount: null,
  },
  existingCredit: {
    currency: null,
    monthlyRate: null,
    name: null,
    repaymentMonths: null,
    totalAmount: null,
    statementTruthful: false,
    paymentProblem: false,
  },
  documents: [],
  email: null,
  phone: null,
  _status: INITIAL_API_STATUS,
};

const extractDocument = (d: any): UploadedDocument => ({
  fileName: d.name,
  typeId: d.doc_type_id,
  ownerId: d.user_personal_id,
  personId: d.personal_id,
});

const transformUser = (response: any): User => {
  const documents = response.data.user.documents.map(extractDocument);
  return {
    selectedLoanId: _.get(
      response.data.user.user_data[0],
      'selected_loan_id',
      null
    ),
    lastOnboardingStepId: _.get(
      response.data.user.user_data[0],
      'last_onboarding_step_id',
      1
    ),
    id: response.data.user.personal_id,
    firstName: nameFormat(response.data.user.first_name),
    lastName: nameFormat(response.data.user.last_name),
    isMarried: response.data.user.is_married,
    spouse: response.data.user.is_married
      ? {
          firstName: nameFormat(response.data.user.spouse[0].firstname),
          lastName: nameFormat(response.data.user.spouse[0].surname),
          pid: response.data.user.spouse[0].pid,
        }
      : null,
    consents: !!response.data.user.user_data[0]
      ? {
          cookie: response.data.user.user_data[0].consent_cookie,
          disclaimer: response.data.user.user_data[0].consent_disclamer,
          gdpr: response.data.user.user_data[0].consent_gdpr,
          marketing: response.data.user.user_data[0].consent_marketing,
        }
      : {
          cookie: false,
          disclaimer: false,
          gdpr: false,
          marketing: false,
        },
    requestedCredit: !!response.data.user.user_data[0]
      ? {
          currency: response.data.user.user_data[0].req_credit_currency,
          monthlyRate: response.data.user.user_data[0].req_credit_monthly_rate,
          name: response.data.user.user_data[0].req_credit_name,
          repaymentMonths:
            response.data.user.user_data[0].req_credit_repayment_months,
          totalAmount: response.data.user.user_data[0].req_credit_total,
        }
      : null,
    existingCredit: !!response.data.user.user_data[0]
      ? {
          currency: response.data.user.user_data[0].credit_currency,
          monthlyRate: response.data.user.user_data[0].credit_monthly_rate,
          name: response.data.user.user_data[0].credit_name,
          repaymentMonths:
            response.data.user.user_data[0].credit_repayment_months,
          totalAmount: response.data.user.user_data[0].credit_total,
          statementTruthful:
            response.data.user.user_data[0].credit_statement_truthful,
          paymentProblem:
            response.data.user.user_data[0].credit_payment_problem,
        }
      : null,
    documents,
    email: _.get(
      response.data.user.user_data[0],
      'user_email',
      null
    ),
    phone: _.get(
      response.data.user.user_data[0],
      'user_phone',
      null
    ),
  };
};

const fetchUser = createApiReducer(
  [
    UserActionTypes.FETCH_USER_START,
    UserActionTypes.FETCH_USER_SUCCESS,
    UserActionTypes.FETCH_USER_FAILURE,
  ],
  transformUser,
  data => data
);

export default chainReducers(initialState, fetchUser);
