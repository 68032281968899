import React from 'react';
import NumberFormat from 'react-number-format';

import { NumberFormatInputCmp, NumberFormatInputProps } from './types';
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR } from './const';

const NumberFormatInput: NumberFormatInputCmp = (
  props: NumberFormatInputProps
) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalSeparator={DECIMAL_SEPARATOR}
      thousandSeparator={THOUSAND_SEPARATOR}
      isNumericString
      allowNegative={false}
    />
  );
};

export default NumberFormatInput;
