import { State } from 'app';
import { isLoading, isEmpty } from 'api';
import { GeneratedDocumentType } from '../types';
import _ from 'lodash';

const getGeneratedDocumentsState = (state: State) => state.generatedDocuments;

export const getGeneratedDocuments = (state: State) =>
  state.generatedDocuments.generatedDocuments;

export const getGeneratedDocumentsLoading = (state: State): boolean => {
  return isLoading(getGeneratedDocumentsState(state));
};

export const getGeneratedDocumentsEmpty = (state: State): boolean => {
  return isEmpty(getGeneratedDocumentsState(state));
};

export const getLastProposal = (state: State) => {
  const documents = getGeneratedDocuments(state);
  
  const proposal = _.findLast(documents, [
    'documentType',
    GeneratedDocumentType.PROPOSAL,
  ]);
  return proposal;
}