import { createApiReducer, chainReducers, INITIAL_API_STATUS } from 'api';
import { GeneratedDocumentsActionTypes } from './actions';

import { GeneratedDocumentState } from './types';
import { GeneratedDocument, GeneratedDocuments } from '../types';

const initialState: GeneratedDocumentState = {
  _status: INITIAL_API_STATUS,
  generatedDocuments: []
};

const extractGeneratedDocument = (document: any): GeneratedDocument => ({
  personalId: document.personal_id,
  description: document.doc_desc,
  userSessionId: document.user_session_id,
  id: document.id,
  fileContentType: document.file_content_type,
  documentType: document.doc_type,
  fileSize: document.file_size,
  signatureDate: document.sig_date,
  timestamp: document.timestamp,
  status: document.status,
});

const transformGeneratedDocuments = (response: any): GeneratedDocuments => {
  return {
    generatedDocuments: response.data.map(extractGeneratedDocument)
  }
};

const fetchGeneratedDocuments = createApiReducer(
  [
    GeneratedDocumentsActionTypes.FETCH_GENERATED_DOCUMENTS_START,
    GeneratedDocumentsActionTypes.FETCH_GENERATED_DOCUMENTS_SUCCESS,
    GeneratedDocumentsActionTypes.FETCH_GENERATED_DOCUMENTS_FAILURE,
  ],
  transformGeneratedDocuments,
  data => data
);

export default chainReducers(initialState, fetchGeneratedDocuments);
