import { SnackbarProviderProps, OptionsObject } from 'notistack';

/**
 * Global options for snackbar provider
 */
export const snackbarOptions: SnackbarProviderProps = {
  maxSnack: 3,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  autoHideDuration: 4000,
};

interface SnackBars {
  [eventName: string]: [string, OptionsObject];
}

/**
 * Object with snackbars user in App
 */
export const SNACK_BARS: SnackBars = Object.freeze({
  authFail: [
    'Log in failed, please try again.',
    {
      variant: 'warning',
    },
  ],
  authSuccess: [
    'You logged in successfully, welcome.',
    {
      variant: 'success',
    },
  ],
  registrationFail: [
    'Registration in failed, please try again.',
    {
      variant: 'warning',
    },
  ],
  registrationSuccess: [
    'Successfully registered. Please log in.',
    {
      variant: 'success',
    },
  ],
});
