import _ from 'lodash';

import { api } from '../config';
import { StartAction, SuccessAction, FailureAction } from './types';

type StartActionFn = () => StartAction;
type SuccessActionFn = (data: any) => SuccessAction;
type FailureActionFn = (data: any) => FailureAction;

/**
 * Function which creates a thunk action for given api config and associated action types
 * @param {string|Object} apiConfig if string it is interprated as path in api endpoint
 * @param {string} apiConfig.hostname hostname of the api, with a default values set in '../config'
 * @param {string} apiConfig.path path on the hostname which combined give an api endpoint
 * @param {string} apiConfig.method HTTP request method, defaults to 'GET'
 * @param {Object} apiConfig.headers headers to be used in HTTP request
 */
export const createApiAction = (
  apiConfig: any,
  startAction: StartActionFn,
  successAction: SuccessActionFn,
  failureAction: FailureActionFn
) => async (dispatch: any) => {
  const hostname = apiConfig.hostname || api.hostname;
  const path = _.isString(apiConfig) ? apiConfig : apiConfig.path;
  const method = _.get(apiConfig, 'method', 'GET');
  const contentType =
    _.includes(['POST', 'PUT', 'PATCH'], method) && !apiConfig.formData
      ? {
          'Content-Type': 'application/json',
        }
      : {};
  const passedHeaders = _.get(apiConfig, 'headers', {});
  const headers = {
    ...contentType,
    ...passedHeaders,
  };

  dispatch(startAction());

  try {
    const response = await fetch(`${hostname}${path}`, {
      method,
      headers,
      body: JSON.stringify(apiConfig.body),
    });

    const responseData = await response.json();
    const additionalData = apiConfig.additionalData || {};

    if (response.ok) {
      await dispatch(successAction({ ...responseData, ...additionalData }));
      return { ok: true };
    }

    await dispatch(
      failureAction(
        responseData || {
          message: `${response.status} - ${response.statusText}`,
        }
      )
    );

    return {
      ok: false,
      errorMessage: _.get(responseData, 'errorMessage'),
    };
  } catch (e) {
    await dispatch(failureAction({ message: e.message }));
    return {
      ok: false,
      errorMessage: e.message,
    };
  }
};
